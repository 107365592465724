import { useNavigate } from "react-router-dom";
import {
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Typography } from "antd";
import React, { useState } from "react";
import Dashboard from "../pages/dashboard";
import Operatorprofile from "../pages/operatorprofile";
import DriverList from "../pages/driverlist";
import RemovedDriverList from "../pages/removeddrivershistory";
import SubHeader from "../components/subheader";
import ActiveDriversList from "../components/activeDriversList";
const { Header, Content, Footer, Sider } = Layout;

function Dashboardscreen() {
  const navigate = useNavigate();
  const [selectedMenu, setSelectedMenu] = useState("fleet-summary");

  const onLogout = () => {
    navigate("/");
  };

  {
    /* <h2>Dashboard</h2>
      <button onClick={onLogout}>Logout</button> */
  }

  const navigateToMenu = (evt) => {
    console.log("navigateToMenu", evt.key);
  };

  const selectActiveComponent = () => {
    if (selectedMenu == "fleet-summary") {
      return <Operatorprofile />;
    }

    if (selectedMenu == "drivers-summary") {
      return <ActiveDriversList />;
    }
    if (selectedMenu == "deleted-drivers-summary") {
      return <RemovedDriverList />;
    }
  };

  {
    console.log("selectedMenu", selectedMenu);
  }

  return (
    <div
      className="site-layout-background"
      style={{
        padding: 24,
        minHeight: 360,
      }}
    >
      {/* content page... based on the side-menu selected, we should load the
      content... each menu will have separate PAGE ( not screen ) */}
      <Header
        className="site-layout-sub-header-background"
        style={{
          padding: 0,
          display: true ? "block" : "none",
          marginBottom: 32,
          // background:'red'
        }}
      >
        <SubHeader setSelectedMenu={setSelectedMenu} />
      </Header>
      {selectActiveComponent()}
    </div>
  );
}

export default Dashboardscreen;

