import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import {
  addVehicleAPIHelper,
  updateVehicleAPIHelper,
  fetchAllVehiclesAPIHelper,
  fetchVehicleMakeAPIHelper,
  fetchVehicleModelAPIHelper,
  fetchVehicleTypeAPIHelper,
  deleteVehicleAPIHelper,
  fetchAllVehicleLogsAPIHelper,
  fetchSingelVehicleLogsAPIHelper,
  assignVehicleToDriverAPIHelper,
  findVehicleByPlateNoAPIHelper,
  fetchVehicleDetailsNZTAAPIHelper,
  addVehicleWithNZTAAPIHelper,
  handleVehicleBulkUpload,
  unassignVehicleToDriverAPIHelper,
  assignVehicleToMultipleDriverAPIHelper,
  fetchVehicleAssignedDriversAPIHelper,
} from "../../helpers/vehicleAPI";
import { renewAccessToken } from "./authReducer";

// https://documenter.getpostman.com/view/14141667/VUxLwoP5#f872736e-5889-4d53-a371-68842528d905

const initialState = {
  activeVehicle: null,
  activeDriver: null,

  vehiclesList: null,
  vehiclesListLoadError: false,

  VehiclesMake: null,
  VehiclesModel: null,
  VehicleType: null,
  AllVehicleLogs: null,
  SingleVehicleLogs: null,
  SingleVehicleLogsDate: null,
  isDateSelected: true,
  AssignedDriver: null,
  vehicleDataViaModal: "",
};

export const addVehicle = createAsyncThunk(
  "vehicle/addVehicle",
  async (payload, thunkAPI) => {
    try {
      const response = await addVehicleAPIHelper(payload); // userAPI.fetchById(userId)
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

export const addVehicleWithNZTA = createAsyncThunk(
  "vehicle/addVehicleWithNZTA",
  async (payload, thunkAPI) => {
    try {
      const response = await addVehicleWithNZTAAPIHelper(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  },
);

export const updateVehilce = createAsyncThunk(
  "vehicle/updateVehicle",
  async (payload, thunkAPI) => {
    try {
      const response = await updateVehicleAPIHelper(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

export const deleteVehilce = createAsyncThunk(
  "vehicle/deleteVehicle",
  async (payload, thunkAPI) => {
    try {
      const response = await deleteVehicleAPIHelper(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchAllVehiclesAPI = createAsyncThunk(
  "vehicle/fetchAllVehicles",
  async (token, thunkAPI) => {
    try {
      const response = await fetchAllVehiclesAPIHelper(token);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

export const fetchAllVehiclesWithModalAPI = createAsyncThunk(
  "vehicle/fetchAllVehiclesWithModal",
  async (payload, thunkAPI) => {
    try {
      const response = await fetchVehicleDetailsNZTAAPIHelper(payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

export const fetchVehicleMake = createAsyncThunk(
  "vehicle/fetchVehiclesMake",
  async (thunkAPI) => {
    try {
      const response = await fetchVehicleMakeAPIHelper();
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

export const fetchVehicleModels = createAsyncThunk(
  "vehicle/fetchVehiclesModel",
  async (vehiclemakeID, thunkAPI) => {
    try {
      const response = await fetchVehicleModelAPIHelper(vehiclemakeID);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

export const fetchVehicleTypes = createAsyncThunk(
  "vehicle/fetchVehiclesTypes",
  async (thunkAPI) => {
    try {
      const response = await fetchVehicleTypeAPIHelper();
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

export const fetchAllVehicleLogs = createAsyncThunk(
  "vehicle/fetchAllVehicleLogs",
  async (token, thunkAPI) => {
    try {
      const response = await fetchAllVehicleLogsAPIHelper(token);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

export const fetchSingelVehicleLogs = createAsyncThunk(
  "vehicle/fetchSingelVehicleLogs",
  async (payload, thunkAPI) => {
    try {
      if (!payload?.activeVehicle) {
        return thunkAPI.rejectWithValue("invalid data");
      }

      const response = await fetchSingelVehicleLogsAPIHelper(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

export const assignVehicleToDriver = createAsyncThunk(
  "vehicle/assignVehicleToDriver",
  async (payload, thunkAPI) => {
    try {
      const response = await assignVehicleToDriverAPIHelper(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

export const unassignVehicleFromDriverAPI = createAsyncThunk(
  "vehicle/unassignVehicleFromDriverAPI",
  async (payload, thunkAPI) => {
    try {
      const response = await unassignVehicleToDriverAPIHelper(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

export const assignVehicleToMultipleDriverAPI = createAsyncThunk(
  "vehicle/assignVehicleToMultipleDriverAPIHelper",
  async (payload, thunkAPI) => {
    try {
      const response = await assignVehicleToMultipleDriverAPIHelper(payload);
      return response;
    } catch (err) {
      return thunkAPI?.rejectWithValue(err);
    }
  },
);

export const findVehicleByPlateNo = createAsyncThunk(
  "vehicle/findVehicleByPlateNo",
  async (payload, thunkAPI) => {
    try {
      const response = await findVehicleByPlateNoAPIHelper(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);
export const fetchVehicleAssignedDrivers = createAsyncThunk(
  "operator/fetchVehicleAssignedDrivers",
  async (payload, thunkAPI) => {
    console.log(payload, "payload from thunk ");

    try {
      const response = await fetchVehicleAssignedDriversAPIHelper(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
export const vehicleBulkUpload = createAsyncThunk(
  "vehicle/vehicleBulkUpload",
  async (payload, thunkAPI) => {
    try {
      const response = await handleVehicleBulkUpload(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

export const vehicleSlice = createSlice({
  name: "vehicle",
  initialState,
  reducers: {
    // get all vehicles
    fetchAllVehicles: (state, action) => {
      state.vehiclesList = action.payload;
    },

    addVehicleToList: (state, action) => {
      // state.vehiclesList = state.vehiclesList.push(action.value);
      console.log("action.value", action);
    },

    selectVehicle: (state, action) => {
      state.activeVehicle = action.payload;
    },

    selectDriver: (state, action) => {
      state.activeDriver = action.payload;
    },
    fetchDateofSingleVehicleLog: (state, action) => {
      state.SingleVehicleLogsDate = action.payload;
    },
    emptyDateofSingleVehicleLog: (state, action) => {
      state.SingleVehicleLogsDate = null;
    },
    DateSelected: (state, action) => {
      state.isDateSelected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      // Fetch and update All Vehicles
      .addCase(fetchAllVehiclesAPI.fulfilled, (state, action) => {
        state.vehiclesList = action?.payload?.vehiclelist;
        console.log("action", JSON.stringify(action));
      })
      .addCase(fetchAllVehiclesAPI.rejected, (state, action) => {
        state.vehiclesList = [];
        state.vehiclesListLoadError = true;
        console.log("action", JSON.stringify(action));
      })

      // Add vehicle
      .addCase(addVehicle.fulfilled, (state, action) => {
        // state.vehiclesList = [...state.vehiclesList, action.value];
        // state.vehiclesList = state.vehiclesList.push(action.value);
        console.log("action", JSON.stringify(action));
      })
      .addCase(addVehicle.rejected, (state, action) => {
        console.log("action", JSON.stringify(action));
        // const dispatch = useDispatch();

        // let token = useSelector((state) => state?.persist?.auth?.token);
        // let refreshToken = useSelector(
        //   (state) => state?.persist?.auth?.refreshToken
        // );
        // console.log("auth token & refresh Token", token, refreshToken);
        // dispatch(renewAccessToken(token, refreshToken));
      })
      .addCase(addVehicleWithNZTA.fulfilled, (state, action) => {
        console.log("action", JSON.stringify(action));
      })
      .addCase(addVehicleWithNZTA.rejected, (state, action) => {
        console.log("action", JSON.stringify(action));
      })
      .addCase(updateVehilce.fulfilled, (state, action) => {
        console.log("action", JSON.stringify(action));
      })
      .addCase(updateVehilce.rejected, (state, action) => {
        console.log("action", JSON.stringify(action));
      })
      .addCase(deleteVehilce.fulfilled, (state, action) => {
        console.log("action", JSON.stringify(action));
      })
      .addCase(deleteVehilce.rejected, (state, action) => {
        console.log("action", JSON.stringify(action));
      })
      .addCase(fetchVehicleMake.fulfilled, (state, action) => {
        state.VehiclesMake = action.payload.data.vehicleMake;
        console.log("action", JSON.stringify(action));
      })
      .addCase(fetchVehicleMake.rejected, (state, action) => {
        // state.VehiclesMake = [];
        console.log("action", JSON.stringify(action));
      })

      .addCase(fetchVehicleModels.fulfilled, (state, action) => {
        state.VehiclesModel = action.payload.data.vehicle_models;
        console.log("action", JSON.stringify(action));
      })
      .addCase(fetchVehicleModels.rejected, (state, action) => {
        // state.VehiclesModel = [];
        console.log("action", JSON.stringify(action));
      })

      .addCase(fetchVehicleTypes.fulfilled, (state, action) => {
        state.VehicleType = action.payload.data.vehicle_types;
        console.log("action", JSON.stringify(action));
      })
      .addCase(fetchVehicleTypes.rejected, (state, action) => {
        // state.VehicleType = [];
        console.log("action", JSON.stringify(action));
      })
      .addCase(fetchAllVehicleLogs.fulfilled, (state, action) => {
        state.AllVehicleLogs = action.payload.data.vehiclelist;
        console.log("action", JSON.stringify(action));
      })
      .addCase(fetchAllVehicleLogs.rejected, (state, action) => {
        console.log("action", JSON.stringify(action));
      })
      .addCase(fetchSingelVehicleLogs.fulfilled, (state, action) => {
        state.SingleVehicleLogs = action.payload.data.data;
        console.log("action", JSON.stringify(action));
        console.log("action.payload.data", action.payload.data.data);
      })
      .addCase(fetchSingelVehicleLogs.rejected, (state, action) => {
        console.log("action", JSON.stringify(action));
      })
      .addCase(assignVehicleToDriver.fulfilled, (state, action) => {
        console.log("action", JSON.stringify(action));
      })
      .addCase(assignVehicleToDriver.rejected, (state, action) => {
        console.log("action", JSON.stringify(action));
      })
      .addCase(fetchAllVehiclesWithModalAPI.fulfilled, (state, action) => {
        state.vehicleDataViaModal = action.payload.data;
        console.log("action", JSON.stringify(action));
      })
      .addCase(fetchAllVehiclesWithModalAPI.rejected, (state, action) => {
        console.log("action", JSON.stringify(action));
      })
      .addCase(vehicleBulkUpload.rejected, (state, action) => {
        console.log("action", JSON.stringify(action));
      })
      .addCase(vehicleBulkUpload.fulfilled, (state, action) => {
        console.log("action", JSON.stringify(action));
      })
      .addCase(fetchVehicleAssignedDrivers.rejected, (state, action) => {
        console.log("action", JSON.stringify(action));
      })
      .addCase(fetchVehicleAssignedDrivers.fulfilled, (state, action) => {
        state.AssignedDriver = action.payload;
        console.log("action ", JSON.stringify(action));
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  fetchAllVehicles,
  incrementByAmount,
  addVehicleToList,
  updateVehicle,
  deleteVehicle,
  selectVehicle,
  selectDriver,
  fetchDateofSingleVehicleLog,
  DateSelected,
  emptyDateofSingleVehicleLog,
} = vehicleSlice.actions;

export default vehicleSlice.reducer;

