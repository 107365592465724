import { Col, Row, message, Spin } from "antd";
import React, { useEffect, useState } from "react";
import Operatordetails from "../components/operatordetails";
import Operatorsubscriptionhistory from "../components/operatorsubscriptionhistory";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchActiveDriversLocations,
  opratorProfile,
  setoperatorProfileData,
} from "../store/reducers/operatorReducer";
import { fetchAllDrivers } from "../store/reducers/driverReducer";
import { fetchAllVehiclesAPI } from "../store/reducers/vehicleReducer";
import { ElogMapComponent } from "../components/googleMapsAPI";
import stopFlag from "../assets/images/red-flag.png";
// import { fetchAllVehiclesAPI } from "../store/reducers/vehicleReducer";

function Operatorprofile() {
  const dispatch = useDispatch();
  const profile = useSelector(
    (state) => state?.persist?.operator?.operatorProfile?.operator,
  );

  const activeDriversMapsLocations = useSelector(
    (state) => state?.persist?.operator?.activeDriversLocations?.data,
  );

  const image =
    "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png";

  const vehicleStartLocations = [];
  const vehicleStopLocations = [];

  if (activeDriversMapsLocations !== null) {
    activeDriversMapsLocations?.map((data) => {
      if (data?.currentLocation !== null) {
        vehicleStartLocations?.push({
          icon: stopFlag,
          latlng: {
            lat: data?.currentLocation?.lat,
            lng: data?.currentLocation?.lng,
          },
          details: {
            detailsType: "Actively Driving",
            address: data?.currentLocation?.formatted_address,
            driverLicense: data?.driverLicense,
            driverName: data?.driverName,
            vehiclemake: data?.vehiclemake_nzta,
            vehiclemodel: data?.vehiclemodel_nzta,
          },
        });
      }
    });
  }
  console.log("profile", profile);
  const access_token = useSelector((state) => state?.persist?.auth.token);
  const [loadingSpin, setLoadingSpinner] = useState(false);

  const [vehicleLocations, setVehicleLocations] = useState([]);

  useEffect(() => {
    if (profile !== undefined) {
      if (profile.isAdmin) {
        dispatch(setoperatorProfileData(profile.isAdmin));
      }

      const image =
        "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png";

      const locations = [
        {
          driverId: 25,
          driverName: "Benij Ibansko",
          driverLicense: "9065GGB",
          vehiclemake: "Audi",
          vehiclemodel: "R8",
          currentLocation: {
            lat: 37.4219977,
            lng: -122.0840409,
            formatted_address:
              "1600 Amphitheatre Pkwy, Mountain View, CA 94043, USA",
          },
        },
      ];

      setTimeout(() => {
        if (window.google) {
          setVehicleLocations(locations);
        }
      }, 4000); // give time for loading all map components
    }
  }, [profile]); // always have arrays inside useeffect

  // const [messageApi, contextHolder] = message.useMessage();

  // alert(JSON.stringify(operator_profile))

  // const [operator_profile, setOperatorProfile] = useState({})
  // const [driverL, setDriverL] = useState([])

  useEffect(() => {
    // const access_token = JSON.parse(localStorage.getItem("access_token"));
    if (access_token !== "") {
      setLoadingSpinner(true);
      dispatch(opratorProfile(access_token)).then((result) => {
        // alert(JSON.stringify(result.payload.data.success))
        setLoadingSpinner(false);
        // if(result.payload.data.success) {
        //   setLoadingSpin(false)
        // }
      });
      dispatch(fetchAllDrivers(access_token)).then((res) => {
        // alert(JSON.stringify(res.payload.data))
      });
      dispatch(fetchAllVehiclesAPI(access_token));
      dispatch(fetchActiveDriversLocations(access_token));
    }
  }, [dispatch]);

  return (
    <div>
      {/* {contextHolder} */}
      <Spin spinning={loadingSpin} size="large">
        <Row
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,
          }}
        >
          <Col span={6} style={{ height: "52vh" }}>
            <Operatordetails parentToChild={profile} />
          </Col>
          <Col span={18}>
            <Operatorsubscriptionhistory />
            {/* <DashboardMap /> */}
            <ElogMapComponent locations={{ vehicleStartLocations }} />
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default Operatorprofile;

