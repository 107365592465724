import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import { Provider } from "react-redux";
import store from "./store/store.js";

// import Updatedriver from './pages/updatedriver'
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import Navigations from "./routes/navigation";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { clientId } from "./config/firebaseConfig.js";

let persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<p>Loading....</p>} persistor={persistor}>
        <BrowserRouter>
          <GoogleOAuthProvider clientId={clientId}>
            <Navigations />
          </GoogleOAuthProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
    ,
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

