import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"; // fetchDriverAPIsHelper
import {
  fetchDriverAPIsHelper,
  addDriverAPIsHelper,
  deleteDriverAPIsHelper,
  updateDriverAPIsHelper,
  fetchPendingDriverInvitationAPIsHelper,
  acceptDriverInvitationAPIsHelper,
  rejectDriverInvitationAPIsHelper,
  fetchRejectedDriverAPIsHelper,
  fetchSingleDriverLogsAPIsHelper,
  pdfLogofDriverAPIsHelper,
  fetchSingleDriverLogsDataAPIsHelper,
  verifyDriverEmailAPIHelper,
  deleteDriverAccountHelper,
} from "../../helpers/driverAPI";

const initialState = {
  drivers: [],
  pending: [],
  activeDrivers: [],
  inactiveDrivers: [],
  success_message: "",
  error_message: "",
  activeDriverLogs: [],
  rejected: [],
  singedriverlogs: [],
  singleLogsofDriver: [],
  pdfLink: "",
  filterDateForLog: "",
  selectedPDFReportLink: "",

  driverEmailVerificationData: {},
};

export const fetchAllDrivers = createAsyncThunk(
  "driver/fetch-driver",
  async (token, thunkAPI) => {
    try {
      const response = await fetchDriverAPIsHelper(token);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

export const addDriver = createAsyncThunk(
  "driver/add-driver",
  async (payload, thunkAPI) => {
    // console.log("1234" + JSON.stringify(driver_data.values))
    try {
      const response = await addDriverAPIsHelper(payload); // userAPI.fetchById(userId) deleteDrivers
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

export const deleteDriverData = createAsyncThunk(
  "driver/delete-driver",
  async (payload, thunkAPI) => {
    try {
      const response = await deleteDriverAPIsHelper(payload);
      console.log("payload123", payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

export const updateDriverProfile = createAsyncThunk(
  "driver/update-driver",
  async (payload, thunkAPI) => {
    try {
      const response = await updateDriverAPIsHelper(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

export const fetchPendingDriverInvitation = createAsyncThunk(
  "driver/fetchPendingDriverInvitation",
  async (token, thunkAPI) => {
    // console.log("rrr")
    try {
      const response = await fetchPendingDriverInvitationAPIsHelper(token); // userAPI.fetchById(userId)
      console.log("fetchPendingDriverInvitation", response);

      return response;
    } catch (err) {
      // console.log("rrr")

      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

export const acceptDriverInvitation = createAsyncThunk(
  "driver/acceptDriverInvitation",
  async (payload, thunkAPI) => {
    try {
      const response = await acceptDriverInvitationAPIsHelper(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

export const fetchRejectedDriver = createAsyncThunk(
  "driver/fetchRejectedDriver",
  async (token, thunkAPI) => {
    // console.log("rrr")
    try {
      const response = await fetchRejectedDriverAPIsHelper(token); // userAPI.fetchById(userId)
      console.log("fetchRejectedDriver", response);

      return response;
    } catch (err) {
      // console.log("rrr")

      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

export const fetchSingelDriverLogs = createAsyncThunk(
  "driver/fetchSingelDriverLogs",
  async (payload, thunkAPI) => {
    console.log("payload", payload);
    try {
      const response = await fetchSingleDriverLogsAPIsHelper(payload); // userAPI.fetchById(userId)
      console.log("fetchSingelDriverLogs", response);

      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

export const fetchSingelDriverLogData = createAsyncThunk(
  "driver/fetchSingelDriverLogData",
  async (payload, thunkAPI) => {
    console.log("payload", payload);
    try {
      const response = await fetchSingleDriverLogsDataAPIsHelper(payload); // userAPI.fetchById(userId)
      console.log(" ", response);

      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

export const rejectDriverInvitation = createAsyncThunk(
  "driver/rejectDriverInvitation",
  async (payload, thunkAPI) => {
    try {
      const response = await rejectDriverInvitationAPIsHelper(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

export const pdfLogofDriver = createAsyncThunk(
  "driver/pdf",
  async (payload, thunkAPI) => {
    try {
      const response = await pdfLogofDriverAPIsHelper(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

export const setDriverEmailVerificationData = createAsyncThunk(
  "driver/verifyDriverEmail",
  async (emailVerificationID, thunkAPI) => {
    try {
      const response = await verifyDriverEmailAPIHelper(emailVerificationID);
      console.log("driverEmailVerification", response);

      return response;
    } catch (err) {
      console.log("driverEmailVerification', err: " + err);
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);
export const setDriverAccountDeletionData = createAsyncThunk(
  "driver/deleteDriverAccount",
  async (driverId, thunkAPI) => {
    try {
      const response = await deleteDriverAccountHelper(driverId);
      console.log("Driver Delete response", response);

      return response;
    } catch (err) {
      console.log("Driverdeleteresponse', err: " + err);
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);
export const driverSlice = createSlice({
  name: "driver",
  initialState,
  reducers: {
    fetchDriver: (state, action) => {
      state.drivers = action.payload;
    },
    addDriver: (state, action) => {
      state.drivers = action.payload;
    },
    deleteDriverData: (state, action) => {
      state.drivers = action.payload;
    },
    updateDriverProfile: (state, action) => {
      state.drivers = action.payload;
    },
    fetchPendingDriverInvitation: (state, action) => {
      state.pending = action.payload;
    },
    acceptDriverInvitation: (state, action) => {
      state.drivers = action.payload;
    },
    fetchRejectedDriver: (state, action) => {
      state.rejected = action.payload;
    },
    fetchSingelDriverLogs: (state, action) => {
      state.singedriverlogs = action.payload;
    },
    fetchSingelDriverLogData: (state, action) => {
      state.singedriverlogs = action.payload;
    },
    pdfLogofDriver: (state, action) => {
      state.singedriverlogs = action.payload;
    },
    // Action for selecting the date for the filter in driver details
    filtrDateForLog: (state, action) => {
      state.filterDateForLog = action.payload;
    },
    setPDFReportLink: (state, action) => {
      state.selectedPDFReportLink = action.payload;
    },
    setDriverEmailVerificationData: (state, action) => {
      state.driverEmailVerificationData = action.payload;
    },
    setDriverAccountDeletionData: (state, action) => {
      state.driverAccountDeletion = action.payload;
    },
  },
  extraReducers: (builder) => {
    // TODO: Add cases for - deleted driver, updated driver,
    // TODO: everytime an action is made on a driver profile, we should update state.drivers array accordingly. Otherwise, stale data will be shown to users.
    builder
      .addCase(fetchAllDrivers.fulfilled, (state, action) => {
        if (!action.payload.success) {
          // alert(action.payload.error)
          state.drivers = [];
          console.log("sucessactio1", action.payload.error);
        } else {
          state.drivers = action?.payload?.data;
        }
        // state.error_message = action.payload.data.error
        console.log("action1s", JSON.stringify(state.drivers));
      })
      .addCase(fetchAllDrivers.rejected, (state, action) => {
        state.drivers = null;
        // if (!action.payload.data.success) {
        //   //  alert(action.payload.data.error)
        // }
        console.log("action1s1", JSON.stringify(state.drivers));
      })
      .addCase(addDriver.fulfilled, (state, action) => {
        state.success_message = action?.payload;
      })
      .addCase(addDriver.rejected, (state, action) => {
        state.error_message = action.payload.data;
        if (!action.payload.data.success) {
          /// alert(action.payload.data.error)
        }
        // alert(state.error_message)
      })
      .addCase(deleteDriverData.fulfilled, (state, action) => {
        state.success_message = action.payload.data.message;
        console.log("action1s", JSON.stringify(state.drivers));
        console.log("action", JSON.stringify(action));
        console.log("deleteDriverDataSuccess");
      })
      .addCase(deleteDriverData.rejected, (state, action) => {
        state.error_message = action.payload.data.error;
        alert(state.error_message);
        console.log("action1s", JSON.stringify(state.drivers));
        console.log("action", JSON.stringify(action));
      })
      .addCase(updateDriverProfile.fulfilled, (state, action) => {
        state.success_message = action.payload.data.message;
        console.log("action", JSON.stringify(state.drivers));
      })
      .addCase(updateDriverProfile.rejected, (state, action) => {
        state.drivers = null;
        console.log("action", JSON.stringify(action));
      })
      .addCase(fetchPendingDriverInvitation.fulfilled, (state, action) => {
        state.pending = action.payload.data.data;
        // state.error_message = action.payload.data.error
        console.log("action", JSON.stringify(state.drivers));
      })
      .addCase(fetchPendingDriverInvitation.rejected, (state, action) => {
        state.pending = null;
      })
      .addCase(acceptDriverInvitation.fulfilled, (state, action) => {
        state.success_message = action.payload.data.message;
      })
      .addCase(acceptDriverInvitation.rejected, (state, action) => {
        state.error_message = action.payload?.data?.error;
        // alert(state.error_message).
        console.log("acceptaction", action);
      })
      .addCase(rejectDriverInvitation.fulfilled, (state, action) => {
        state.success_message = action.payload.data.message;
      })
      .addCase(rejectDriverInvitation.rejected, (state, action) => {
        state.error_message = action.payload?.data?.error;
      })
      .addCase(fetchRejectedDriver.fulfilled, (state, action) => {
        state.rejected = action.payload.data.data;
        if (!action.payload.data.data) {
          // alert(action.payload.data.data[0].id)
        }
        // state.error_message = action.payload.data.error
        console.log("action1s", JSON.stringify(state.drivers));
      })
      .addCase(fetchRejectedDriver.rejected, (state, action) => {
        state.rejected = null;
        if (!action?.payload?.data?.success) {
          // alert(action.payload.data.error)
        }
      })
      .addCase(fetchSingelDriverLogs.fulfilled, (state, action) => {
        state.singedriverlogs = action.payload.data;
        if (!action.payload?.data?.success) {
          // alert(action.payload.data.error)
        }
        // state.error_message = action.payload.data.error
        // console.log('action1s', JSON.stringify(state.drivers))
      })
      .addCase(fetchSingelDriverLogs.rejected, (state, action) => {
        state.singedriverlogs = null;
        if (!action.payload?.data?.success) {
          //  alert(action.payload.data.error)
        }
      })
      .addCase(pdfLogofDriver.fulfilled, (state, action) => {
        state.pdfLink = action.payload.data;
        if (!action.payload?.data?.success) {
          // alert(action.payload.data.error)
        }
        // state.error_message = action.payload.data.error
        // console.log('action1s', JSON.stringify(state.drivers))
      })
      .addCase(pdfLogofDriver.rejected, (state, action) => {
        state.pdfLink = null;
        if (!action.payload?.data?.success) {
          //  alert(action.payload.data.error)
        }
      })
      .addCase(fetchSingelDriverLogData.fulfilled, (state, action) => {
        state.singleLogsofDriver = action.payload.data;
        if (!action.payload?.data?.success) {
          // alert(action.payload.data.error)x
        }
        console.log("action.payload12", action.payload);
      })
      .addCase(fetchSingelDriverLogData.rejected, (state, action) => {
        state.singleLogsofDriver = [];
        if (state.error_message) {
          // alert(action.payload.data.error)
        }
        console.log("action.payload", action.payload);
      })
      .addCase(setDriverEmailVerificationData.fulfilled, (state, action) => {
        state.driverEmailVerificationData = action.payload.data.data;
        // state.error_message = action.payload.data.error
        console.log("action", JSON.stringify(state.drivers));
      })
      .addCase(setDriverEmailVerificationData.rejected, (state, action) => {
        state.driverEmailVerificationData = null;
      })
      .addCase(setDriverAccountDeletionData.fulfilled, (state, action) => {
        state.driverAccountDeletion = action.payload.data.data;
        // state.error_message = action.payload.data.error
        console.log("action", JSON.stringify(state.drivers));
      })
      .addCase(setDriverAccountDeletionData.rejected, (state, action) => {
        state.driverAccountDeletion = null;
      });
  },
});

// Action creators are generated for each case reducer function
export const { fetchDriver, filtrDateForLog, setPDFReportLink } =
  driverSlice.actions;

export default driverSlice.reducer;

