import { Button, Form, Modal, Spin, message } from "antd";
import Input from "rc-input";
import React, { useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import {
  onBoardingOperator,
  signInUser,
  updateAuthStatus,
} from "../store/reducers/authReducer";

const styles = {
  phoneinput: {
    textOverflow: "ellipsis",
    display: "flex",
  },
  input: {
    width: "100%",
    borderWidth: "1px",
  },
};

const layout = {
  labelCol: {
    span: 9,
  },
  wrapperCol: {
    span: 12,
  },
};

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const OperatorOnboardingModal = ({ visible, onClose, data }) => {
  const [value, setValue] = useState();
  const [messageApi, contextHolder] = message.useMessage();
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (values) => {
    setLoadingSpinner(true);

    const payload = {
      firstname: values?.firstname,
      lastname: values?.lastname,
      phoneNumber: values?.phone_number,
      operatorName: values?.business_name,
      email: data?.email,
      token_id: data?.token_id,
      authProvider: data?.authProvider,
    };
    dispatch(onBoardingOperator(payload)).then((result) => {
      if (result?.payload?.data?.success === true) {
        dispatch(signInUser(data?.token_id))
          .then((result) => {
            if (result.payload.data.token) {
              dispatch(updateAuthStatus(true));
              setLoadingSpinner(false);
              localStorage.removeItem("userData");
              navigate("/app/dashboard");
            }
          })
          .catch((error) => {
            setLoadingSpinner(false);
            console.log("error........", error);
          });
      } else {
        setLoadingSpinner(false);
        const errorMessage = result.payload.data.message;
        messageApi.open({
          type: "warning",
          content: errorMessage,
          className: "custom-class",
          style: {
            marginTop: "10vh",
          },
        });
      }
    });
  };

  const { firstname, lastname, phone_number, business_name } = data;

  return (
    <Spin spinning={loadingSpinner} size="large">
      {contextHolder}
      <Modal
        title="Operator Onboarding"
        centered
        open={visible}
        onOk={onClose}
        onCancel={onClose}
        style={{ width: 800 }}
        footer={false}
      >
        <Form
          name="basic"
          {...layout}
          validateMessages={validateMessages}
          initialValues={{
            firstname: firstname ? firstname : "",
            lastname: lastname ? lastname : "",
            phone_number: phone_number ? phone_number : "",
            business_name: business_name ? business_name : "",
          }}
          onFinish={handleSubmit}
          onFinishFailed={(err) => console.log("Error....", err)}
          autoComplete="off"
          style={styles.text_input}
        >
          <Form.Item
            name="firstname"
            label="First Name"
            rules={[
              {
                required: true,
              },
              {
                required: false,
                pattern: new RegExp(/^[a-zA-Z. ]{0,20}$/),
                message: "Enter a valid First Name",
              },
            ]}
          >
            <Input style={styles.input} placeholder="Enter First Name" />
          </Form.Item>
          <Form.Item
            name="lastname"
            label="Last Name"
            rules={[
              {
                required: true,
              },
              {
                required: false,
                pattern: new RegExp(/^[a-zA-Z. ]{0,20}$/),
                message: "Enter a valid Last Name",
              },
            ]}
          >
            <Input style={styles.input} placeholder="Enter Last Name" />
          </Form.Item>

          <Form.Item
            name="phone_number"
            label="Phone Number"
            rules={[
              {
                required: true,
              },
              {
                pattern: new RegExp(/^.{5,13}$/),
                message: "Enter valid Phone Number",
              },
            ]}
          >
            <PhoneInput
              placeholder="Enter phone number"
              defaultCountry="NZ"
              style={styles.phoneinput}
              value={value}
              onChange={setValue}
              className="ant-input"
            />
          </Form.Item>
          <Form.Item
            name="business_name"
            label="Business Name"
            rules={[
              {
                required: true,
              },
              {
                pattern: new RegExp(/^[a-zA-Z0-9- ]{0,50}$/),
                message: "Enter a valid Business Name",
              },
            ]}
          >
            <Input style={styles.input} placeholder="Enter Business Name" />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              ...layout.wrapperCol,
              lg: {
                offset: 9,
              },
              md: {
                offset: 9,
              },
              sm: {
                offset: 9,
              },
            }}
          >
            <Button type="primary" htmlType="submit" block>
              REGISTER
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Spin>
  );
};
export default OperatorOnboardingModal;

