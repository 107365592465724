import {
  Spin,
  Table,
  Button,
  Layout,
  PageHeader,
  Breadcrumb,
  Card,
  Input,
  Space,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import { assignVehicleToMultipleDriverAPI } from "../store/reducers/vehicleReducer";
import toast from "react-hot-toast";

const AssigningMultipleDriverToVehicle = () => {
  const { Search } = Input;
  let { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loadingSpin, setLoadingSpinner] = useState(false);
  const [filterInput, setFilterInput] = useState("");
  const authtoken = useSelector((state) => state?.persist?.auth?.token);
  const { drivers } = useSelector((state) => state?.persist?.driver);

  const activeVehicle = useSelector(
    (state) => state?.persist?.vehicle?.activeVehicle,
  );

  const operator = useSelector(
    (state) =>
      state?.persist?.operator?.operatorProfile?.operator?.operatorOrganization,
  );
  console.log(operator, "selectedDriverIds");
  const [selectedIds, setSelectedIds] = useState([]);

  const backToPrev = () => {
    navigate(`/app/vehicles/vehicledetails/${id}`);
  };

  const filterData = () => {
    if (filterInput === "") return drivers;
    if (isNaN(filterInput)) {
      return drivers.filter(({ licenseNumber }) =>
        licenseNumber.toUpperCase().includes(filterInput.toUpperCase()),
      );
    }
  };

  useEffect(() => {
    filterData();
  }, [filterInput.length == 0]);

  const assignVehicleToMultipleDriver = () => {
    const payload = {
      vehicleid: id,
      token: authtoken,
      driverids: selectedIds,
    };
    if (payload) {
      setLoadingSpinner(true);
      dispatch(assignVehicleToMultipleDriverAPI(payload)).then((result) => {
        setLoadingSpinner(false);
        if (result?.payload?.data?.success === true) {
          toast(result?.payload?.data?.data);
          setTimeout(() => {
            navigate(`/app/vehicles/vehicledetails/${id}`);
          }, 2000);
        }
        console.log("testing response", result?.payload?.response?.data?.data);
        if (!result?.payload?.response?.success) {
          toast(result?.payload?.response?.data?.data);
        }
        setLoadingSpinner(false);
      });
    }
  };

  const handleCheckboxChange = (item) => {
    if (item?.id) {
      const selectedId = selectedIds.includes(item?.id)
        ? selectedIds.filter((itemId) => itemId !== item?.id)
        : [...selectedIds, item?.id];
      setSelectedIds(selectedId);
    }
  };

  const DriverListTable = () => {
    const assignedDriver = useSelector(
      (state) => state?.persist?.vehicle?.AssignedDriver?.data?.data?.drivers,
    );
    const fetchAllDrivers = useSelector(
      (state) => state?.persist?.driver?.drivers,
    );

    let activeDriver = [];
    if (activeVehicle.type === "Heavy Vehicle") {
      const filteredData = fetchAllDrivers?.filter(
        (driver) => driver?.inactive === false && driver?.driverType?.id === 1,
      );
      const filteredDrivers = filteredData.filter(
        (driver) =>
          !assignedDriver.some((assigned) => assigned.id === driver.id),
      );
      filteredDrivers?.map((driverItem) => activeDriver.push(driverItem));
    } else {
      const filteredData = fetchAllDrivers?.filter(
        (driver) => driver?.inactive === false,
      );
      const filteredDrivers = filteredData.filter(
        (driver) =>
          !assignedDriver.some((assigned) => assigned.id === driver.id),
      );

      filteredDrivers?.map((driverItem) => activeDriver.push(driverItem));
    }
    const columnsForFatigueManagement = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (item) => {
          const newName = item.split(" ");
          for (let i = 0; i < newName.length; i++) {
            newName[i] = newName[i][0]?.toUpperCase() + newName[i]?.substr(1);
          }
          return <p>{newName.join(" ")}</p>;
        },
      },
      {
        title: "Phone Number",
        dataIndex: "phoneNumber",
        key: "phoneNumber",
      },
      {
        title: "License Number",
        dataIndex: "licenseNumber",
        key: "licenseNumber",
      },
      {
        title: " License Type",
        dataIndex: "driverType",
        key: "driverType",
        render: (item) => {
          return <p>{item.name}</p>;
        },
      },
      {
        title: `Select Drivers`,
        render: (item) => {
          return (
            <input type="checkbox" checked={selectedIds.includes(item?.id)} />
          );
        },
      },
    ];
    const filterData = () => {
      if (filterInput === "") return activeDriver;
      if (isNaN(filterInput)) {
        return activeDriver.filter(({ licenseNumber }) =>
          licenseNumber.toUpperCase().includes(filterInput.toUpperCase()),
        );
      }
    };

    useEffect(() => {
      filterData();
    }, [filterInput.length == 0]);

    return (
      <div>
        <Spin spinning={loadingSpin} size="large">
          <Table
            dataSource={filterData()}
            scroll={{
              y: 900,
            }}
            size={"middle"}
            columns={columnsForFatigueManagement}
            pagination={false}
            onRow={(record) => ({
              style: { cursor: "pointer" },
              onClick: () => {
                handleCheckboxChange(record);
              },
            })}
          ></Table>
        </Spin>
      </div>
    );
  };
  return (
    <>
      <div
        className="site-layout-background"
        style={{
          padding: 24,
        }}
      >
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/app/vehicles">Vehicles</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/app/vehicles/vehicledetails/${id}`}>
              Vehicle Details
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Assign Multiple Drivers</Breadcrumb.Item>
        </Breadcrumb>
        <div
          style={{
            display: "flex",
            marginLeft: 30,
            marginRight: 30,
            marginTop: 30,
            alignItems: "center",
          }}
        >
          <Card
            title={
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "normal",
                  textAlign: "left",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                My Drivers
              </span>
            }
            extra={[
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex", marginRight: 50 }}>
                  <Search
                    style={{ width: "40vh" }}
                    placeholder="Search driver by license number"
                    allowClear
                    enterButton="Search"
                    size="medium"
                    onSearch={setFilterInput}
                  />
                </div>
                <Button
                  onClick={() => assignVehicleToMultipleDriver()}
                  style={{ width: "100%" }}
                  disabled={selectedIds?.length === 0 ? true : false}
                  type={selectedIds?.length !== 0 ? "primary" : null}
                >
                  {selectedIds?.length === 1
                    ? `ASSIGN DRIVER (${selectedIds.length})`
                    : `ASSIGN DRIVERS (${selectedIds.length})`}
                </Button>
                <Space style={{ fontSize: "18px" }}></Space>
              </div>,
            ]}
          >
            <DriverListTable />
          </Card>
        </div>
      </div>
    </>
  );
};

export default AssigningMultipleDriverToVehicle;

