
import { useNavigate, useNavigation } from 'react-router-dom';
import Login from '../pages/login';
import Signup from '../pages/signup';

function AuthenticationScreen(props) {

  // const navigation = useNavigate()
  console.log('AuthenticationScreen path', props, )

  return (
    <div className="App">
      {
        props?.path === 'login' ? <Login /> : <Signup />
      }
    </div>
  );
}

export default AuthenticationScreen;
