import { Button, Form, Input, Select, message } from "antd";
import React, { useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addVehicleWithNZTA,
  fetchAllVehiclesAPI,
  fetchAllVehiclesWithModalAPI,
  fetchVehicleModels,
  fetchVehicleTypes,
} from "../store/reducers/vehicleReducer";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 10,
  },
};

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const AddVehicleWithNZTA = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let accesstoken = useSelector((state) => state?.persist?.auth?.token);
  let token = useSelector((state) => state?.persist?.auth?.token);
  const [messageApi, contextHolder] = message.useMessage();
  const [NZTZVehicleCheckDone, setNZTZVehicleCheckDone] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(fetchVehicleTypes());
  }, []);

  let vehiclesstate = useSelector((state) => state?.persist?.vehicle);
  console.log(vehiclesstate.VehicleType, "VehicleType");
  const onNZTAVehicleCheck = (values) => {
    const payload = {
      model: values?.Model,
      accesstoken: accesstoken,
    };
    dispatch(fetchAllVehiclesWithModalAPI(payload)).then((response) => {
      if (response?.payload?.data != "null") {
        const parsedResponse = JSON.parse(response?.payload?.data);
        setNZTZVehicleCheckDone(true);
        form.setFieldsValue({
          Make: parsedResponse[15],
          Model: parsedResponse[16],
        });
      } else if (response?.payload?.data == "null") {
        let errormsg = document.getElementById("Errormsg");
        errormsg.innerText = "This model is not available";
      }
    });
  };

  const CheckNewVehicle = () => {
    onReset();
    setNZTZVehicleCheckDone(false);
  };

  const onFinish = (values) => {
    const payload = {
      accessToken: accesstoken,
      vehicleMakeNZTA: values?.Make,
      vehicleModelNZTA: values?.Model,
      vehicletypeID: values?.Type,
      fleetCode: values.FleetCode,
      plateNumber: values?.PlateNo,
      initialOdometer: values?.CurrentOdometer,
    };
    dispatch(addVehicleWithNZTA(payload)).then((response) => {
      if (response?.payload?.data?.success) {
        setNZTZVehicleCheckDone(false);
        if (response.payload.data.message !== undefined) {
          messageApi.open({
            type: "success",
            content: response.payload.data.message,
            className: "custom-class",
            style: {
              marginTop: "10vh",
            },
          });
        }
        dispatch(fetchAllVehiclesAPI(token));
        props.onAddVehicleHandler("list-of-vehicles");
        onReset();
      } else if (!response.payload.data.success) {
        if (response.payload.data.error !== undefined) {
          messageApi.open({
            type: "warning",
            content: response.payload.data.error,
            className: "custom-class",
            style: {
              marginTop: "10vh",
            },
          });
        }
        if (response.payload.data.message !== undefined) {
          messageApi.open({
            type: "warning",
            content: response.payload.data.message,
            className: "custom-class",
            style: {
              marginTop: "10vh",
            },
          });
        }
      }
    });
  };

  const onReset = () => {
    form.resetFields();
  };

  const makeChanged = (e) => {
    const vehiclemakeID = e;
    dispatch(fetchVehicleModels(vehiclemakeID));
  };

  const styles = {
    text_input: {
      textAlign: "left",
    },
  };
  return (
    <>
      {!NZTZVehicleCheckDone ? (
        <>
          <Form
            {...layout}
            form={form}
            name="nest-messages"
            onFinish={onFinish}
            validateMessages={validateMessages}
          >
            {contextHolder}
            <Form.Item
              name="Make"
              label="Make"
              style={styles.text_input}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
                placeholder="Make"
              />
            </Form.Item>
            <Form.Item
              name="Model"
              label="Model"
              style={styles.text_input}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
                placeholder="Model"
              />
            </Form.Item>
            <Form.Item
              name="FleetCode"
              label="Fleet Code"
              style={styles.text_input}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
                placeholder="Fleet Code"
              />
            </Form.Item>

            <Form.Item
              name="Type"
              label="Type"
              style={styles.text_input}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select placeholder="Select Type" allowClear>
                {vehiclesstate?.VehicleType?.map((e, i) => {
                  return (
                    <Option key={i} value={e?.id}>
                      {e?.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="PlateNo"
              label="Plate No"
              style={styles.text_input}
              rules={[
                {
                  required: true,
                },
                {
                  required: false,
                  pattern: new RegExp(/^[a-zA-Z0-9-]{0,14}$/),
                  message: "Enter a valid Plate No",
                },
              ]}
            >
              <Input
                onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
                placeholder="Plate No"
                // disabled={true}
              />
            </Form.Item>
            <Form.Item
              name="CurrentOdometer"
              label="Current Odometer"
              style={styles.text_input}
              rules={[
                {
                  required: false,
                  pattern: new RegExp(/^[0-9]{0,7}$/),
                  message: "Enter a valid Odometer",
                },
              ]}
            >
              <Input suffix="KM" placeholder="Current Odometer" />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                ...layout.wrapperCol,
                lg: {
                  offset: 8,
                },
                md: {
                  offset: 8,
                },
                sm: {
                  offset: 8,
                },
              }}
            >
              <Button
                id="addVehicleButton"
                type="primary"
                htmlType="submit"
                block
              >
                ADD VEHICLE
              </Button>
              {/* <Button
                type="default"
                block
                onClick={CheckNewVehicle}
                style={{ marginTop: 10 }}
              >
                Try Different Vehicle
              </Button> */}
              <p id="success_msg"></p>
            </Form.Item>
            {/* <Model /> */}
          </Form>
        </>
      ) : (
        <>
          <Form
            {...layout}
            form={form}
            name="nest-messages"
            onFinish={onNZTAVehicleCheck}
            validateMessages={validateMessages}
          >
            {contextHolder}
            <Form.Item
              name="Model"
              label="Model"
              style={styles.text_input}
              rules={[
                {
                  required: true,
                },
                {
                  required: false,
                  pattern: new RegExp(/^[a-zA-Z0-9-]{0,14}$/),
                  message: "Enter a valid model",
                },
              ]}
            >
              <Input
                onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
                placeholder="Enter model Name"
              />
            </Form.Item>
            <p id="Errormsg" style={{ color: "red", textAlign: "center" }}></p>
            <Form.Item
              wrapperCol={{
                ...layout.wrapperCol,
                lg: {
                  offset: 8,
                },
                md: {
                  offset: 8,
                },
                sm: {
                  offset: 8,
                },
              }}
            >
              <Button
                id="addVehicleButton"
                type="primary"
                htmlType="submit"
                block
              >
                SEARCH VEHICLE
              </Button>
            </Form.Item>
          </Form>
        </>
      )}
    </>
  );
};
export default AddVehicleWithNZTA;

