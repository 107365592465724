import { useNavigate } from "react-router-dom";
import {
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Typography } from "antd";
import React, { useState } from "react";
import DriversList from "../pages/driverslist";
import AddDriver from "../pages/adddriver";
import Organization from "../pages/organization";
import SubHeader from "../components/subheader";
import RemoveOperatorHistory from "../pages/removeoperatorhistory";
import DriverRejected from "../pages/driverrejected";

const { Header, Content, Footer, Sider } = Layout;

function OrganizationScreen() {
  const navigate = useNavigate();
  const [selectedMenu, setSelectedMenu] = useState("operator-list");

  const onLogout = () => {
    navigate("/");
  };

  const selectActiveComponent = () => {
    if (selectedMenu == "operator-list") {
      return <Organization/>;
    }

    if (selectedMenu == "operator-remove") {
      return <RemoveOperatorHistory />;
    }


  };

  return (
    <div
      className="site-layout-background"
      style={{
        padding: 24,
        minHeight: 360,
      }}
    >
      <Header
        className="site-layout-sub-header-background"
        style={{
          padding: 0,
          display: true ? "block" : "none",
          marginBottom: 32,
          // background:'red'
        }}
      >
        <SubHeader setSelectedMenu={setSelectedMenu} />
      </Header>
      {selectActiveComponent()}
    </div>
  );
}

export default OrganizationScreen;
