import { Avatar, Card } from "antd";
import React from "react";
// import { UserOutlined } from "@ant-design/icons";
import {
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
  IdcardOutlined,
  GiftOutlined,
} from "@ant-design/icons";

const { Meta } = Card;

const Driverdetails = (props) => (
  <Card
    title="Driver Details"
    type="inner"
    style={{
      width: 300,
    }}
    size="small"
    bodyStyle={{ paddingTop: "10px", textAlign: "center" }}
  >
    <UserOutlined
      style={{
        fontSize: 30,
        paddingBottom: "5px",
        textAlign: "center",
      }}
    />

    <Meta style={{ marginBottom: "2px" }} />
    <p style={{ marginBottom: "2px", fontWeight: "bold" }}>
      {props?.activeDriverData?.name}
    </p>
    <p style={{ marginBottom: "2px" }}>
      <MailOutlined /> {props?.activeDriverData?.email}
    </p>
    <p style={{ marginBottom: "2px" }}>
      <GiftOutlined />{" "}
      {props?.activeDriverData?.dateOfBirth?.split("-").reverse().join("-")}
    </p>
    <p style={{ marginBottom: "2px" }}>
      <PhoneOutlined /> {props?.activeDriverData?.phoneNumber}
    </p>
    <p style={{ marginBottom: "2px" }}>
      <IdcardOutlined /> {props?.activeDriverData?.licenseNumber}
    </p>
  </Card>
);

export default Driverdetails;

