import { Layout } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function SettingScreen() {
  const navigate = useNavigate();

  const onLogout = () => {
    navigate('/');
  };

  return (
    <div
      className="site-layout-background"
      style={{
        padding: 24,
        minHeight: 360,
      }}
    >
      Operator Setting content page... based on the side-menu selected, we should load the
      content... each menu will have separate PAGE ( not screen )
    </div>
  );
}

export default SettingScreen;
