import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Divider,
  PageHeader,
  Row,
  Select,
  Space,
} from "antd";
import { Link, useMatch, useNavigate } from "react-router-dom";
import Vehicledetails from "../components/vehicledetails";
import Vehicleloglist from "../components/vehicleloglist";
import {
  fetchAllVehiclesAPI,
  fetchDateofSingleVehicleLog,
} from "../store/reducers/vehicleReducer";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { ElogMapComponent } from "../components/googleMapsAPI";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useState } from "react";
import stopFlag from "../assets/images/red-flag.png";
import startFlag from "../assets/images/finish-point.png";
import AssignedDriverList from "../components/AssignedDriverList";
function Singlevehicledetails() {
  const {
    params: { id },
  } = useMatch("/app/vehicles/vehicledetails/:id");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const image =
    "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png";

  const stopimage =
    "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png";

  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const [PDFLink, setPDFLink] = useState("true");
  const [DisablePDFLink, setDisablePDFLink] = useState(true);
  const vehicleStartLocations = [];
  const vehicleStopLocations = [];
  const pathCoordinates = [];
  let SingleVehicleLogs = useSelector(
    (state) => state?.persist?.vehicle?.SingleVehicleLogs,
  );

  if (SingleVehicleLogs !== null && SingleVehicleLogs !== undefined) {
    SingleVehicleLogs?.map((data) =>
      vehicleStartLocations?.push({
        icon: startFlag,
        latlng: { lat: data?.start_coord?.lat, lng: data?.start_coord?.lng },
        details: {
          detailsType: "Vehicle",
          address: data?.start_coord?.formatted_address,
          driverName: data?.driverName,
        },
      }),
    );
  }

  if (SingleVehicleLogs !== null && SingleVehicleLogs !== undefined) {
    SingleVehicleLogs?.map((data) =>
      vehicleStopLocations?.push({
        icon: stopFlag,
        latlng: { lat: data?.stop_coord?.lat, lng: data?.stop_coord?.lng },
        details: {
          detailsType: "Vehicle",
          address: data?.stop_coord?.formatted_address,
          driverName: data?.driverName,
        },
      }),
    );
  }

  if (SingleVehicleLogs !== null && SingleVehicleLogs !== undefined) {
    SingleVehicleLogs?.map((data) =>
      pathCoordinates?.push([
        { lat: data?.start_coord?.lat, lng: data?.start_coord?.lng },
        { lat: data?.stop_coord?.lat, lng: data?.stop_coord?.lng },
      ]),
    );
  }

  const AllDatesforLogs = [];
  if (SingleVehicleLogs !== null && SingleVehicleLogs !== undefined) {
    let map = new Map();

    for (let item of SingleVehicleLogs) {
      if (!map.has(moment(item?.start).format("DD-MM-YYYY"))) {
        map.set(moment(item?.start).format("DD-MM-YYYY"), true);
        AllDatesforLogs?.push({
          value: item?.id,
          label: moment(item?.start).format("DD-MM-YYYY"),
        });
      }
    }
  }

  const handleChange = (data, value) => {
    console.log(value?.label, "date123");

    dispatch(fetchDateofSingleVehicleLog(value));
  };

  const Filter = () => (
    <div>
      <Space wrap>
        <Select
          defaultValue={"Select Date"}
          // showSearch
          style={{
            width: 140,
          }}
          onChange={handleChange}
          options={AllDatesforLogs}
        />
      </Space>
    </div>
  );
  const backToPrev = () => {
    navigate(`/app/vehicles`);
  };
  return (
    <div
      className="site-layout-background"
      style={{ padding: 24, minHeight: 360 }}
    >
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/app/vehicles">Vehicle List</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Vehicle Details</Breadcrumb.Item>
      </Breadcrumb>
      <Row gutter={[16, 16]} style={{ width: "100%", marginTop: 50 }}>
        <Col xs={24} sm={12}>
          <Vehicledetails Id={id} />
        </Col>
        <Col xs={24} sm={12}>
          <ElogMapComponent
            locations={{
              vehicleStartLocations,
              vehicleStopLocations,
              pathCoordinates,
            }}
          />
        </Col>
      </Row>
      <Divider />
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12}>
          <AssignedDriverList id={id} />
        </Col>
        <Col xs={24} sm={12}>
          <Card
            Style={{ padding: 0, margin: 0 }}
            title={
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "normal",
                  textAlign: "left",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Logs
              </span>
            }
            size="small"
            extra={<Filter />}
          >
            <Vehicleloglist />
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Singlevehicledetails;

