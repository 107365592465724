import {
  Avatar,
  Card,
  Modal,
  Result,
  Button,
  Row,
  Divider,
  message,
  Spin,
  Table,
  Switch,
  Col,
} from "antd";
import {
  CheckOutlined,
  ArrowLeftOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  createBillingAddress,
  getAllSubcriptionPlans,
  getBillingAddress,
  onPaymentSuccess,
  preCheckout,
} from "../store/reducers/paymentReducer";
import { Helmet } from "react-helmet";

function SubscriptionPlans() {
  const dispatch = useDispatch();
  const SubscriptionPlans = useSelector(
    (state) => state?.persist?.payment?.paymentPlans,
  );
  const token = useSelector((state) => state?.persist?.auth?.token);

  const operatorEmail = useSelector(
    (state) => state?.persist?.operator?.operatorProfile?.operator?.email,
  );

  useEffect(() => {
    dispatch(getAllSubcriptionPlans());
    dispatch(preCheckout(token));
  }, []);
  const navigate = useNavigate();
  const backToPrev = () => {
    navigate(`/app/dashboard`);
  };
  const handleSwitchClick = () => {
    if (planPeriodYearly === false) {
      setPlanPeriodYearly(true);
    }
    if (planPeriodYearly === true) {
      setPlanPeriodYearly(false);
    }
  };
  const [planPeriodYearly, setPlanPeriodYearly] = useState(false);

  return (
    <div>
      <ArrowLeftOutlined
        style={{
          width: 60,
          fontSize: 20,
          paddingTop: 30,
          paddingLeft: 30,
          display: "flex",
          alignItems: "flex-start",
        }}
        onClick={() => backToPrev()}
      />

      <Col
        style={{
          display: "flex-row",
          justifyContent: "center",
          marginTop: "10px",
        }}
      >
        <h2>Subscription Plans</h2>
      </Col>
      <Col
        style={{
          marginTop: "20px",
        }}
      >
        <Row
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          {SubscriptionPlans
            ? SubscriptionPlans?.map((plans) => {
                return (
                  <div>
                    <Card
                      title={plans?.name}
                      // extra={<a href="#">More</a>}
                      headStyle={{ fontSize: "16px" }}
                      style={{
                        width: 350,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "50px",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginRight: "5px",
                          }}
                        >
                          {plans?.cost}
                        </p>
                        <p>{plans?.currency}</p>

                        <p> {` / ${plans?.billingCycle}`}</p>
                      </div>
                      <Button
                        size="middle"
                        style={{ marginBottom: "15px" }}
                        onClick={() => {
                          window.location.href = `https://elogbook.chargebee.com/hosted_pages/checkout?customer[email]=${operatorEmail}&subscription_items[item_price_id][0]=${plans.planId}-${plans.currency}-${plans.billingCycle}&subscription_items[quantity][0]=1&layout=in_app`;
                        }}
                      >
                        SUBSCRIBE
                      </Button>
                      {plans?.planBenefits?.map((planBenefits) => {
                        return (
                          <Col
                            style={{
                              paddingLeft: 15,
                              paddingRight: 15,
                              textAlign: "left",
                            }}
                          >
                            <p>
                              <span>
                                <CheckOutlined
                                  style={{
                                    color: "blue",
                                    fontSize: "13px",
                                    paddingRight: 5,
                                  }}
                                />
                              </span>
                              {planBenefits?.benefitName}
                            </p>
                          </Col>
                        );
                      })}

                      {/* <p>
                  <span>
                    <CloseOutlined
                      style={{ color: "red", fontSize: "13px", paddingRight: 5 }}
                    />
                  </span>
                  Export Unlimited Logs
                </p> */}
                    </Card>
                  </div>
                );
              })
            : null}
        </Row>
        {/* <Link to="/app/dashboard/subscribe/success?sub_id=Azz5a0TX3Z3lXUJA&invoice_id=6491&id=cdTQSwauFxZcun1yGdfxwEeFn8MIUKqqdt&state=succeede">
          Trans_id
        </Link>
        <Button onClick={() => navigate("/app/dashboard/subscribe/success")}>
          OnPaymentSuccess
        </Button> */}
      </Col>
    </div>
  );
}

export default SubscriptionPlans;

