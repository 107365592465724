import { Col, Typography, Button, Divider, Layout, Menu, Row } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import { setDriverEmailVerificationData } from "../store/reducers/driverReducer";
import { useDispatch } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import logo from "../assets/images/white-.png";

const { Text, Title } = Typography;

function DriverEmailVerification() {
  const {
    params: { id },
  } = useMatch("/email/verification/:id");
  const [data, setData] = useState("");
  const styles = {
    text_input: { textAlign: "left", fontSize: "1.8rem", fontWeight: "bold" },
  };

  const dispatch = useDispatch();
  // const isFirstRender = useRef(true);
  // useEffect(() => {
  //   if (id) {
  //     dispatch(setDriverEmailVerificationData(id))
  //       .then((result) => {
  //         console.log("driverScreenresult", result);
  //         if (result?.payload?.data != null) {
  //           const payloadData = result?.payload?.data;
  //           setData(payloadData);
  //         }
  //       })
  //       .catch((err) => {
  //         console.log("driverScreenresult", err);
  //       });
  //   }
  // }, [id]);
  return (
    <div className="App">
      <header className="App-header">
        <div className="App-navbar">
          <div className="App-navitem">
            <Row
              style={{
                paddingLeft: 10,
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={logo}
                height={60}
                width={60}
                style={{ borderRadius: 8 }}
              />
            </Row>
          </div>
        </div>
      </header>
      {data ? (
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 150,
          }}
        >
          {" "}
          <Title direction="horizontal" level={4}>
            {" "}
            {data?.success ? (
              <CheckCircleOutlined
                style={{ fontSize: "10vh", color: "green" }}
              />
            ) : data?.success === false ? (
              <CloseCircleOutlined style={{ fontSize: "10vh", color: "red" }} />
            ) : null}
          </Title>{" "}
          <Text direction="horizontal" style={styles.text_input}>
            {" "}
            {data?.messageTitle}{" "}
          </Text>{" "}
          <Text style={{ fontSize: "1rem" }}> {data?.messageDescription} </Text>{" "}
        </Col>
      ) : (
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 150,
          }}
        >
          {" "}
          <Title direction="horizontal" level={4}>
            <ExclamationCircleOutlined
              style={{ fontSize: "10vh", color: "orange" }}
            />
          </Title>{" "}
          <Text direction="horizontal" style={styles.text_input}>
            {" "}
            Email Verification
          </Text>{" "}
          <Text style={{ fontSize: "1rem" }}>
            {" "}
            Click on the verify button to confirm the verification
          </Text>{" "}
          <Button
            type="primary"
            style={{ marginTop: 10 }}
            onClick={() => {
              dispatch(setDriverEmailVerificationData(id))
                .then((result) => {
                  console.log("driverScreenresult", result);
                  if (result?.payload?.data != null) {
                    const payloadData = result?.payload?.data;
                    setData(payloadData);
                  }
                })
                .catch((err) => {
                  console.log("driverScreenresult", err);
                });
            }}
          >
            VERIFY EMAIL
          </Button>
        </Col>
      )}
    </div>
  );
}
export default DriverEmailVerification;

