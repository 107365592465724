import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
  CarOutlined,
  ProfileOutlined,
  TeamOutlined,
  SendOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  UserDeleteOutlined,
} from "@ant-design/icons";
import { Button, Menu, Row } from "antd";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const menuItems = [
  {
    pathname: "/app/dashboard",
    items: [
      {
        label: "Fleet Summary",
        key: "fleet-summary",
        icon: <CarOutlined />,
      },
      {
        label: "Active Drivers",
        key: "drivers-summary",
        icon: <CheckCircleOutlined />,
      },
      {
        label: "Removed Drivers ",
        key: "deleted-drivers-summary",
        icon: <CloseCircleOutlined />,
      },
    ],
  },
  {
    pathname: "/app/vehicles",
    items: [
      {
        label: "Vehicle List",
        key: "list-of-vehicles",
        icon: <ProfileOutlined />,
      },
    ],
  },
  {
    pathname: "/app/bulkupload",
    items: [
      {
        label: "Vehicle List",
        key: "list-of-vehicles",
        icon: <ProfileOutlined />,
      },
    ],
  },
  {
    pathname: "/app/drivers",
    items: [
      {
        label: "My Drivers",
        key: "drivers-summary",
        icon: <TeamOutlined />,
      },
      {
        label: "Driver Requests",
        key: "driver-invitation",
        icon: <SendOutlined />,
      },
      {
        label: "Rejected Drivers",
        key: "driver-rejected",
        icon: <ExclamationCircleOutlined />,
      },
    ],
  },
  {
    pathname: "/app/settings",
    items: [
      {
        label: "Add Vehicle",
        key: "add-vehicle",
        icon: <CarOutlined />,
      },
      {
        label: "Vehicle List",
        key: "list-of-vehicles",
        icon: <ProfileOutlined />,
      },
    ],
  },
  {
    pathname: "/app/Users",
    items: [
      {
        label: "Current users",
        key: "operator-list",
        icon: <ProfileOutlined />,
      },
      {
        label: "Past users",
        key: "operator-remove",
        icon: <UserDeleteOutlined />,
      },
    ],
  },
];

const SubHeader = (props) => {
  const navigate = useNavigate();
  const currentLocation = useLocation();

  const [currentMenu, setCurrentMenu] = useState(menuItems[0]);
  const [current, setCurrent] = useState(menuItems[0].items[0]);

  useEffect(() => {
    let selectedMenu = menuItems.filter(
      (menuItem) => menuItem.pathname === currentLocation.pathname,
    );

    if (selectedMenu.length > 0) {
      setCurrentMenu(selectedMenu[0]);
    } else {
      setCurrentMenu(menuItems[0]);
    }
  }, [currentLocation]);

  const onClick = (e) => {
    console.log("click ", e);
    setCurrent(e.key);
    props.setSelectedMenu(e.key);
    // // navigate(`/${evt.key}`);
    // if (!currentLocation.pathname.includes(`${currentLocation.pathname}/${e.key}`)) {
    //   navigate(`${currentLocation.pathname}/${e.key}`);
    // }
  };

  return (
    <Menu
      onClick={onClick}
      selectedKeys={[current]}
      mode="horizontal"
      defaultActiveFirst={true}
      defaultSelectedKeys={currentMenu.items[0]}
      items={currentMenu.items}
    />
  );
};

export default SubHeader;

