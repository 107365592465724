import { CloseOutlined } from "@ant-design/icons";
import { Card } from "antd";
import React from "react";
import logo from "../assets/images/white-.png";

const DeviceDetectModal = ({ onClose }) => {
  const contentText = () => (
    <div style={{ textAlign: "center", fontSize: 15 }}>
      <p>This website is not optimized for mobile devices.</p>
      <p>
        We recommend using a desktop or laptop computer for the best experience.
      </p>
    </div>
  );

  return (
    <div className="card-container">
      <Card
        style={{
          margin: 0,
          padding: 0,
          backgroundColor: "#002244",
          borderRadius: 10,
          color: "white",
          maxWidth: 350,
          boxShadow: "4px 4px 10px 2px rgba(4, 3, 1, 5)",
        }}
        title={
          <p
            style={{
              marginBottom: 0,
              fontWeight: "bolder",
              color: "white",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <img
              src={logo}
              height={30}
              width={30}
              style={{ borderRadius: 8, marginRight: 5, marginTop: 0 }}
            />
            <p
              style={{
                marginBottom: 0,
              }}
            >
              Notification
            </p>

            <CloseOutlined
              onClick={onClose}
              style={{
                marginTop: 5,
                marginBottom: 0,
              }}
            />
          </p>
        }
      >
        {contentText()}
      </Card>
    </div>
  );
};

export default DeviceDetectModal;

