import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDzrjlxiP450JFJ0Al0rkl3e0XYWSDHWvA",
  authDomain: "picobyte-solutions.firebaseapp.com",
  projectId: "picobyte-solutions",
  storageBucket: "picobyte-solutions.appspot.com",
  messagingSenderId: "992029135546",
  appId: "1:992029135546:web:5fc9263da0474e67a46883",
  measurementId: "G-CX7QCT7G4P",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging();
export const authentication = getAuth(app);
export const clientId =
  "992029135546-gjgd9bj3pop3l3nhter2l139sbmq0kba.apps.googleusercontent.com";
export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey: `BI8UM0E-aJTajhoVbFwWeabO3aFRoEOf0jY7rRGZXEg8oirHbVL31Jewa0BUwTn5AkdYET3jpL79KYBW6T4x9IQ`,
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token client: ", currentToken);
        return currentToken;
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one.",
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

