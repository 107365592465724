import React, { useRef, useState, useEffect } from "react";
import "antd/dist/antd.css";
import { EditOutlined, SnippetsOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  Space,
  Table,
  Modal,
  Form,
  Select,
  Spin,
  message,
  Row,
  Col,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllVehiclesAPI,
  fetchSingelVehicleLogs,
  updateVehilce,
} from "../store/reducers/vehicleReducer";
import moment from "moment";
import Search from "antd/lib/transfer/search";

const VehicleScrollList = (props) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [isHover, setIsHover] = useState(false);
  const [loading, setLoading] = useState(false);
  const [violationsData, setViolationsData] = useState([]);
  const [isViolationModalOpen, setIsViolationModalOpen] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.persist?.auth?.token);
  const vehicles = useSelector(
    (state) => state?.persist?.vehicle?.vehiclesList,
  );
  const activeVehicle = useSelector(
    (state) => state?.persist?.vehicle?.activeVehicle,
  );
  let vehiclesstate = useSelector((state) => state?.persist?.vehicle);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    setLoading(true);
    dispatch(fetchAllVehiclesAPI(token)).finally(() => setLoading(false));
    dispatch(
      fetchSingelVehicleLogs({ token, activeVehicle: activeVehicle?.id }),
    );
  }, [dispatch, token, activeVehicle]);

  const handleEditVehicle = (vehicle) => {
    console.log("what is the vehicle data", vehicle);
    setSelectedVehicle(vehicle);
    setIsUpdateModalOpen(true);
    form.setFieldsValue({
      id: vehicle.id,
      Model: vehicle.model_nzta,
      Make: vehicle.make_nzta,
      FleetCode: vehicle.fleetCode,
      Type: vehicle?.type
        ? vehiclesstate?.VehicleType?.filter(
            (e) => e.name === vehicle.type,
          ).map((e) => e.id)[0]
        : null,
      PlateNo: vehicle.plateNumber,
      CurrentOdometer: vehicle.initialOdometer,
    });
  };

  const closeUpdateModal = () => {
    setIsUpdateModalOpen(false);
    form.resetFields();
  };

  // Handle vehicle update
  const handleUpdateVehicle = (values) => {
    setLoading(true);
    const payload = {
      accessToken: token,
      activeVehicle: selectedVehicle?.id,
      make_nzta: values.Make,
      model_nzta: values.Modal,
      vehicletypeID: values.Type,
      fleetCode: values.FleetCode,
      plateNumber: values.PlateNo,
      initialOdometer: values.CurrentOdometer,
    };

    dispatch(updateVehilce(payload))
      .then((result) => {
        if (result.payload?.data?.success) {
          messageApi.open({
            type: "success",
            content: result.payload.data.message,
            className: "custom-class",
            style: {
              marginTop: "10vh",
            },
          });
          dispatch(fetchAllVehiclesAPI(token));
          closeUpdateModal();
        } else {
          messageApi.open({
            type: "warning",
            content: result.payload?.data?.error || "Update failed",
            className: "custom-class",
            style: {
              marginTop: "10vh",
            },
          });
        }
      })
      .finally(() => setLoading(false));
  };

  const handleViolationClick = (record) => {
    setViolationsData(record?.violationDetails || []);
    setIsViolationModalOpen(true);
  };

  const columns = [
    {
      title: "Plate No.",
      dataIndex: "plateNumber",
      key: "plateNumber",
      width: "15%",
      render: (plateNumber, vehicle) => (
        <div className="blink">
          <p
            style={{
              fontSize: 14,
              fontWeight: "600",
              letterSpacing: "2px",
              marginBottom: "0px",
              border: "double",
              display: "flex",
              justifyContent: "center",
              borderRadius: "5px",
              backgroundColor: vehicle?.assignedTo
                ? vehicle?.isActive
                  ? "#05ad48"
                  : "orange"
                : "red",
            }}
          >
            #{plateNumber}
          </p>
        </div>
      ),
    },
    {
      title: "Fleet Code",
      dataIndex: "fleetCode",
      key: "fleetCode",
      width: "10%",
    },
    {
      title: "Make",
      dataIndex: "make_nzta",
      key: "make",
      width: "10%",
    },
    {
      title: "Model",
      dataIndex: "model_nzta",
      key: "model",
      width: "10%",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "10%",
    },
    {
      title: "Odometer (KM)",
      dataIndex: "initialOdometer",
      key: "initialOdometer",
      width: "10%",
      render: (odometer) => <Space>{Number(odometer).toLocaleString()}</Space>,
    },
    // {
    //   title: "Assigned Driver",
    //   dataIndex: "assignedTo",
    //   key: "assignedTo",
    //   width: "10%",
    //   render: (assignedTo) => <Space>{assignedTo || "Unassigned"}</Space>,
    // },
    {
      title: "Violations",
      dataIndex: "violationCount",
      key: "violationCount",
      width: "10%",
      render: (item, record) => (
        <Space>
          <a onClick={() => console.log("anchor is clicked", record)}>{item}</a>
          <a
            onClick={() => {
              setIsViolationModalOpen(true);
              setViolationsData(record?.violationDetails);
            }}
          >
            {" "}
            View
          </a>{" "}
        </Space>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      dataIndex: "",
      render: (record) => (
        <Space>
          <a onClick={() => handleEditVehicle(record)}>
            <EditOutlined /> Edit
          </a>
        </Space>
      ),
    },
    {
      title: "View Logs",
      dataIndex: "",
      width: "9%",
      key: "x",
      render: (record) => (
        <Space size="middle">
          <a
            onClick={(e) => {
              props.onSelectVehicleHandler(record);
            }}
          >
            <SnippetsOutlined /> View
          </a>
        </Space>
      ),
    },
  ];

  return (
    <div>
      {contextHolder}
      <Spin spinning={loading}>
        <Table
          dataSource={props.filterData()}
          columns={columns}
          rowClassName="custom-row-hover"
          pagination={false}
          scroll={{ y: 600 }}
          style={{ paddingTop: 10 }}
        />
      </Spin>

      {/* Modal for updating vehicle */}
      <Modal
        title="Update Vehicle"
        open={isUpdateModalOpen}
        onCancel={closeUpdateModal}
        footer={null}
      >
        <Form
          {...{ labelCol: { span: 8 }, wrapperCol: { span: 12 } }}
          form={form}
          onFinish={handleUpdateVehicle}
        >
          <Form.Item name="Make" label="Make" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="Model" label="Model" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="FleetCode"
            label="Fleet Code"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="Type" label="Type" rules={[{ required: true }]}>
            <Select placeholder="Select Type">
              {vehiclesstate?.VehicleType?.map((e, i) => (
                <Option key={i} value={e.id}>
                  {e.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="PlateNo"
            label="Plate No"
            rules={[
              { required: true },
              {
                pattern: /^[a-zA-Z0-9-]{0,14}$/,
                message: "Enter a valid Plate No",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="CurrentOdometer"
            label="Current Odometer"
            rules={[
              { pattern: /^[0-9]{0,7}$/, message: "Enter a valid Odometer" },
            ]}
          >
            {selectedVehicle?.assignedTo ? (
              <Input
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                disabled={isHover ? true : false}
              />
            ) : (
              <Input />
            )}
          </Form.Item>
          <div style={{ justifyContent: "center", display: "flex" }}>
            {isHover && (
              <span style={{ color: "red", marginTop: -22 }}>
                You are not authorised
              </span>
            )}
          </div>
          <Row justify="space-around">
            <Col span={12}>
              <Button type="primary" htmlType="submit" block>
                UPDATE
              </Button>
            </Col>
            <Col span={11}>
              <Button onClick={() => form.resetFields()} block>
                RESET
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      {/* Modal for viewing violations */}
      <Modal
        title="Violation Details"
        open={isViolationModalOpen}
        onCancel={() => setIsViolationModalOpen(false)}
        width={900}
        footer={null}
      >
        <Table
          dataSource={violationsData}
          columns={[
            {
              title: "Date & Time",
              key: "date",
              render: (record) =>
                moment(record?.createdAt).format("DD-MM-YYYY hh:mm A"),
            },
            { title: "Vehicle", key: "vehicle", dataIndex: "vehicle" },
            {
              title: "Message",
              key: "message",
              dataIndex: ["businessRuleViolated", "rule", "errorMessage"],
            },
            {
              title: "Warning",
              key: "warning",
              dataIndex: ["businessRuleViolated", "rule", "rule_type"],
            },
          ]}
          pagination={false}
          scroll={{ y: 350 }}
        />
      </Modal>
    </div>
  );
};

export default VehicleScrollList;

