import { Col, Row, Spin, message, Modal } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Space, Card } from "antd";
import { useMatch } from "react-router-dom";
import Driverdetails from "../components/driverdetails";
import Driverlogslist from "../components/driverloglist";
import {
  fetchSingelDriverLogData,
  filtrDateForLog,
  setPDFReportLink,
} from "../store/reducers/driverReducer";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { ElogMapComponent } from "../components/googleMapsAPI";
import { Select } from "antd";
import moment from "moment";
import stopFlag from "../assets/images/red-flag.png";
import startFlag from "../assets/images/finish-point.png";
const { Meta } = Card;

function Driverdetailsandactivity(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [logId, setLogId] = useState("");
  const [PDFLink, setPDFLink] = useState("true");
  const [DisablePDFLink, setDisablePDFLink] = useState(true);
  const driverData = useSelector(
    (state) => state?.persist?.driver?.singedriverlogs?.data?.cumulativeLogs,
  );
  const [isAddDriverModalOpen, setAddDriverModal] = useState();
  const closeDriverModal = () => {
    setAddDriverModal(false);
  };
  const DatesforLogs = [];

  if (driverData !== null) {
    driverData?.map((date) => {
      const formateddate = moment(date?.createdAt).format("DD-MM-YY");
      DatesforLogs?.push({
        value: date?.id,
        label: formateddate,
      });
    });
  }
  const image =
    "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png";

  const stopimage =
    "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png";

  const vehicleStartLocations = [];
  const vehicleStopLocations = [];
  const pathCoordinates = [];
  const {
    params: { id },
  } = useMatch("/app/drivers/driver/details/:id");

  let activeDriver = useSelector(
    (state) => state?.persist?.vehicle?.activeDriver,
  );

  let activeDriverData = useSelector(
    (state) => state?.persist?.vehicle?.activeDriver,
  );

  let fetchDriverLogs = useSelector(
    (state) => state?.persist?.driver?.singleLogsofDriver?.data,
  );

  if (fetchDriverLogs !== null) {
    fetchDriverLogs?.map((data) =>
      vehicleStartLocations?.push({
        icon: startFlag,
        latlng: { lat: data?.start_coord?.lat, lng: data?.start_coord?.lng },
        details: {
          detailsType: "Driver",
          address: data?.start_coord?.formatted_address,
          vehicleMake: data?.vehicleMakeNzta,
          vehicleModel: data?.vehicleModelNzta,
          vehiclePlateNumber: data?.vehiclePlateNumber,
        },
      }),
    );
  }

  if (fetchDriverLogs !== null) {
    fetchDriverLogs?.map((data) =>
      vehicleStopLocations?.push({
        icon: stopFlag,
        latlng: { lat: data?.stop_coord?.lat, lng: data?.stop_coord?.lng },
        details: {
          detailsType: "Driver",
          address: data?.stop_coord?.formatted_address,
          vehicleMake: data?.vehicleMakeNzta,
          vehicleModel: data?.vehicleModelNzta,
          vehiclePlateNumber: data?.vehiclePlateNumber,
        },
      }),
    );
  }

  if (fetchDriverLogs !== null) {
    fetchDriverLogs?.map((data) =>
      pathCoordinates?.push([
        { lat: data?.start_coord?.lat, lng: data?.start_coord?.lng },
        { lat: data?.stop_coord?.lat, lng: data?.stop_coord?.lng },
      ]),
    );
  }
  let token = useSelector((state) => state?.persist?.auth?.token);
  let filtrDate = useSelector(
    (state) => state?.persist?.driver?.filterDateForLog,
  );
  let PDFReportLink = useSelector(
    (state) => state?.persist?.driver?.selectedPDFReportLink,
  );
  const [filterDate, setFilterDate] = useState("");
  const [firstRender, setFirstRender] = useState(true);

  const backToPrev = () => {
    navigate(`/app/drivers/driversloghistory/${activeDriver?.id}`);
  };

  let payload = {
    driverid: driverData[0]?.driver?.id,
    logid: logId,
    token: token,
  };

  useEffect(() => {
    FetchSingleDriverLogs();
  }, [logId, filterDate]);

  const FetchSingleDriverLogs = () => {
    if (firstRender !== true) {
      dispatch(fetchSingelDriverLogData(payload)).then(
        dispatch(filtrDateForLog(filterDate?.label)),
      );
    }

    setLoadingSpinner(false);
    driverData?.map((mapdate) => {
      if (logId == mapdate?.id) {
        dispatch(setPDFReportLink(mapdate?.report));
      }
    });
    setFirstRender(false);
  };

  const handleChange = (data, values) => {
    setLoadingSpinner(true);
    setFilterDate(values);
    setLogId(data);
  };

  const Filter = () => (
    <div>
      <Space wrap>
        <Select
          defaultValue={filtrDate}
          // showSearch
          style={{
            width: 140,
          }}
          onChange={handleChange}
          options={DatesforLogs}
        />
      </Space>
    </div>
  );

  return (
    <Spin spinning={loadingSpinner} size="large">
      <div>
        <Modal
          open={isAddDriverModalOpen}
          width={300}
          bodyStyle={{ padding: 0 }}
          onCancel={closeDriverModal}
          footer={false}
        >
          <Driverdetails activeDriverData={activeDriverData} />
        </Modal>
        <Row
          style={{
            display: "flex",
            marginBottom: 10,
            alignItems: "center",
          }}
        >
          <ArrowLeftOutlined
            style={{
              width: 60,
              fontSize: 20,
              paddingTop: 20,
              paddingBottom: 20,
            }}
            onClick={() => backToPrev(id)}
          />
          <h2
            onClick={() => setAddDriverModal(true)}
            style={{ marginTop: 10, cursor: "pointer", fontWeight: "bold" }}
          >
            {activeDriverData?.name}
          </h2>

          {/* <Filter /> */}
        </Row>
        <Row
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,
          }}
          style={{ justifyContent: "space-between", width: "100%", margin: 0 }}
        >
          <Col span={12}>
            <Card
              bodyStyle={{ padding: 0, margin: 0 }}
              title="Logs"
              size="small"
              extra={
                <Row>
                  {" "}
                  <Button
                    style={{
                      marginRight: 10,
                    }}
                    onClick={() => window.open(PDFReportLink, "_blank")}
                  >
                    PRINT PDF
                  </Button>
                  <Filter />
                </Row>
              }
            >
              <Driverlogslist />
            </Card>
          </Col>
          <Col span={12}>
            <ElogMapComponent
              locations={{
                vehicleStartLocations,
                vehicleStopLocations,
                pathCoordinates,
              }}
            />
          </Col>
        </Row>
      </div>
    </Spin>
  );
}

export default Driverdetailsandactivity;

