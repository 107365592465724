import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/authReducer";
import vehicleReducer from "./reducers/vehicleReducer";
import driverReducer from "./reducers/driverReducer";
import operatorReducer from "./reducers/operatorReducer";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";
import paymentReducer from "./reducers/paymentReducer";

// persist config guide https://blog.reactnativecoach.com/the-definitive-guide-to-redux-persist-84738167975
const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const rootReducer = combineReducers({
  auth: authReducer,
  vehicle: vehicleReducer,
  driver: driverReducer,
  operator: operatorReducer,
  payment: paymentReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default configureStore({
  reducer: {
    persist: persistedReducer,
  },
});

// thunk is already inside the toolkit
