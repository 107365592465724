import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import {
  createBillingAddressAPIHelper,
  getAllSubcriptionPlansAPIHelper,
  getBillingAddressAPIHelper,
  onPaymentSuccessAPIHelper,
  paymentStatusAPIHelper,
  preCheckoutAPIHelper,
} from "../../helpers/paymentAPI";
import { renewAccessToken } from "./authReducer";

const initialState = {
  activeSubscriptionPlan: null,
  currentAddress: null,
  paymentPlans: null,
  paymentDetails: null,
};

export const getAllSubcriptionPlans = createAsyncThunk(
  "payments/getAllSubcriptionPlans",
  async (thunkAPI) => {
    try {
      const response = await getAllSubcriptionPlansAPIHelper();
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

export const createBillingAddress = createAsyncThunk(
  "payments/createBillingAddress",
  async (payload, thunkAPI) => {
    try {
      const response = await createBillingAddressAPIHelper(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

export const getBillingAddress = createAsyncThunk(
  "payments/getBillingAddress",
  async (payload, thunkAPI) => {
    try {
      const response = await getBillingAddressAPIHelper(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

export const preCheckout = createAsyncThunk(
  "payments/preCheckout",
  async (token, thunkAPI) => {
    try {
      const response = await preCheckoutAPIHelper(token);
      return response?.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

export const onPaymentSuccess = createAsyncThunk(
  "payments/onPaymentSuccess",
  async (payload, thunkAPI) => {
    try {
      const response = await onPaymentSuccessAPIHelper(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

export const paymentStatus = createAsyncThunk(
  "payments/paymentStatus",
  async (payload, thunkAPI) => {
    try {
      const response = await paymentStatusAPIHelper(payload);
      return response?.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    // get all vehicles
    // fetchAllVehicles: (state, action) => {
    //   state.vehiclesList = action.payload;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllSubcriptionPlans.fulfilled, (state, action) => {
        state.paymentPlans = action.payload?.plans;
        console.log("action", JSON.stringify(action));
      })
      .addCase(getAllSubcriptionPlans.rejected, (state, action) => {
        console.log("action", JSON.stringify(action));
      })
      .addCase(createBillingAddress.fulfilled, (state, action) => {
        console.log("action", JSON.stringify(action));
      })
      .addCase(createBillingAddress.rejected, (state, action) => {
        console.log("action", JSON.stringify(action));
      })
      .addCase(getBillingAddress.fulfilled, (state, action) => {
        console.log("action", JSON.stringify(action));
      })
      .addCase(getBillingAddress.rejected, (state, action) => {
        console.log("action", JSON.stringify(action));
      })
      .addCase(preCheckout.fulfilled, (state, action) => {
        console.log("action", JSON.stringify(action));
      })
      .addCase(preCheckout.rejected, (state, action) => {
        console.log("action", JSON.stringify(action));
      })
      .addCase(onPaymentSuccess.fulfilled, (state, action) => {
        console.log("action", JSON.stringify(action));
      })
      .addCase(onPaymentSuccess.rejected, (state, action) => {
        console.log("action", JSON.stringify(action));
      })
      .addCase(paymentStatus.fulfilled, (state, action) => {
        state.paymentDetails = action.payload;
        console.log("action", JSON.stringify(action));
      })
      .addCase(paymentStatus.rejected, (state, action) => {
        console.log("action", JSON.stringify(action));
      });
  },
});

// Action creators are generated for each case reducer function
export const {} = paymentSlice.actions;

export default paymentSlice.reducer;

