import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  operatorLoginAPIHelper,
  operatorOnboardingAPIHelper,
  fetchOperatorProfileAPIHelper,
  renewAccessTokenAPIHelper,
  notificationsAPIHelper,
} from "../../helpers/authAPI";

const initialState = {
  is_authenticated: false,
  auth_profile: null,
  value: 0,
  errors: null,
  token: "",
  refreshToken: "",
};

export const signInUser = createAsyncThunk(
  "auth/signInUser",
  async (token, thunkAPI) => {
    try {
      const response = await operatorLoginAPIHelper(token); // userAPI.fetchById(userId)
      console.log(response);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

export const onBoardingOperator = createAsyncThunk(
  "auth/onBoardingOperator",
  async (
    token,
    firstname,
    lastname,
    phoneNumber,
    operatorName,
    authProvider,
    thunkAPI,
  ) => {
    try {
      const response = await operatorOnboardingAPIHelper(
        token,
        firstname,
        lastname,
        phoneNumber,
        operatorName,
      );
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

export const fetchOperatorProfile = createAsyncThunk(
  "auth/fetchOperatorProfile",
  async (token, operator_id, thunkAPI) => {
    try {
      const response = await fetchOperatorProfileAPIHelper(token, operator_id);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

export const renewAccessToken = createAsyncThunk(
  "auth/renewAccessToken",
  async (refreshToken, thunkAPI) => {
    try {
      const response = await renewAccessTokenAPIHelper(refreshToken);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
); // resetAuthState getNotifications

export const resetAuthState = createAsyncThunk(
  "auth/resetAuthState",
  async () => {},
);

export const getNotifications = createAsyncThunk(
  "auth/getNotifications",
  async (payload, thunkAPI) => {
    try {
      const response = await notificationsAPIHelper(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

export const authSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    updateAuthStatus: (state, action) => {
      state.is_authenticated = action.payload;
      console.log("state.is_authenticated", action);
    },
    updateAuthProfile: (state, action) => {
      state.auth_profile = action.value;
    },
    resetState: (state, action) => {
      state.token = "";
      state = [];
      console.log("reset state", action);
      console.log("reset state View", state);
    },
    resetAuthState: (state) => {
      state.token = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInUser.fulfilled, (state, action) => {
        // alert(action.payload.data.error)
        if (action?.payload?.data) {
          state.token = action?.payload?.data?.token;
          state.refreshToken = action?.payload?.data?.refreshToken;
        } else {
          state.token = "";
          state.refreshToken = "";
        }
        console.log("login action", JSON.stringify(action));
        console.log("store refresh token", state.refreshToken);
      })
      .addCase(signInUser.rejected, (state, action) => {
        state.auth_profile = null;
        console.log("action", JSON.stringify(action));
      })
      .addCase(fetchOperatorProfile.fulfilled, (state, action) => {
        state.auth_profile = action.payload.value;
        console.log("action", JSON.stringify(action));
      })
      .addCase(onBoardingOperator.fulfilled, (state, action) => {
        // fetch profile
      })
      .addCase(onBoardingOperator.rejected, (state, action) => {
        // save errors and show to user
        state.errors = action.payload.value;
      })
      .addCase(renewAccessToken.fulfilled, (state, action) => {
        state.token = action.payload.data.token;
        state.refreshToken = action.payload.data.refreshToken;
        console.log("action", JSON.stringify(action));
      })
      .addCase(renewAccessToken.rejected, (state, action) => {
        console.log("action", JSON.stringify(action));
      })
      .addCase(resetAuthState.fulfilled, (state) => {
        state.token = "";
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        console.log("mfc_action", JSON.stringify(action));
      })
      .addCase(getNotifications.rejected, (state, action) => {
        console.log("action", JSON.stringify(action));
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  updateAuthStatus,
  updateAuthProfile,
  incrementByAmount,
  resetState,
} = authSlice.actions;

export default authSlice.reducer;

