import { useState } from "react";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Typography,
  InputNumber,
  Checkbox,
  Spin,
  Space,
  message,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateOptr } from "../store/reducers/operatorReducer";
// import { authInfo } from "../store/reducers/authReducer";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const { Text, Link, Title } = Typography;

const styles = {
  phoneinput: {
    // text-overflow: ellipsis,
    // text
  },
};

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
};

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

function Editoperatorprofile() {
  const dispatch = useDispatch();
  //  const access_token = useSelector(state => state?.persist?.auth?.token);
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const access_token = JSON.parse(localStorage.getItem("access_token"));
  const operator_current_profile = useSelector(
    (state) => state?.persist?.operator?.operatorProfile,
  );
  const [value, setValue] = useState();
  const styles = {
    text_input: {
      textAlign: "left",
    },
    // phoneinput: { border: 0 },
  };
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      operator_current_profile !== undefined &&
      operator_current_profile !== null
    ) {
      form.setFieldsValue({
        firstname: operator_current_profile.operator.first_name,
        lastname: operator_current_profile.operator.last_name,
        phoneNumber: operator_current_profile.operator.phoneNumber,
        email: operator_current_profile.operator.email,
        phoneNumber: operator_current_profile.operator.phoneNumber,
        // operator_organization_name: operator_current_profile.operator.operatorOrganization.name
      });
    }
  });

  // const [componentDisabled, setComponentDisabled] = useState(true);

  // const onFormLayoutChange = ({ disabled }) => {
  //   setComponentDisabled(disabled);
  // };

  const onFinish = (operator_updated_profile) => {
    setLoadingSpinner(true);
    const payload = { operator_updated_profile, access_token };
    dispatch(updateOptr(payload)).then((res) => {
      if (res.payload.data.success) {
        setLoadingSpinner(false);
        messageApi.open({
          type: "success",
          content: res.payload.data.message,
          className: "custom-class",
          style: {
            marginTop: "10vh",
          },
        });
        setTimeout(() => {
          navigate("/app/dashboard");
        }, 2000);
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const checkPassword = (rule, value, callback) => {
    const { form } = this.props;
    console.log(form);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
  };
  // const onFill = () => {
  //   setComponentDisabled(false)
  // };

  return (
    <Spin spinning={loadingSpinner} size="large">
      <Col>
        <Title
          direction="horizontal"
          style={{ display: "flex", justifyContent: "center" }}
          level={4}
        >
          {contextHolder}
        </Title>
        <h3 style={{ marginTop: "20px" }}>Operator Profile</h3>
        <Form
          name="basic"
          {...layout}
          validateMessages={validateMessages}
          initialValues={{
            remember: true,
          }}
          onSubmit={onSubmit}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          style={styles.text_input}
          form={form}
          // onValuesChange={onFormLayoutChange}
          // disabled={componentDisabled}
        >
          <Form.Item
            name="firstname"
            label="First Name"
            rules={[
              {
                required: true,
              },
              {
                required: false,
                pattern: new RegExp(/^[a-zA-Z. ]{0,15}$/),
                message: "Enter a valid First Name",
              },
            ]}
          >
            <Input placeholder="Enter First Name" />
          </Form.Item>
          <Col span={2}></Col>
          <Form.Item
            name="lastname"
            label="Last Name"
            rules={[
              {
                required: true,
              },
              {
                required: false,
                pattern: new RegExp(/^[a-zA-Z. ]{0,15}$/),
                message: "Enter a valid Last Name",
              },
            ]}
          >
            <Input placeholder="Enter Last Name" />
          </Form.Item>
          <p style={{ fontSize: 11, marginLeft: "34%" }}>Can't edit email</p>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
              },
              {
                type: "email",
                message: "Enter the valid email",
              },
            ]}
          >
            {/* <p style={{fontSize:12}}>Can't edit email</p> */}
            <Input disabled={true} placeholder="Enter Email" />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            label="Phone Number"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Phone Number" />
          </Form.Item>
          {/* <Form.Item
          name="operator_organization_name"
          label="Operator Organization Name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input disabled={true} placeholder="Operator Organization Name" />
        </Form.Item> */}
          <Form.Item
            wrapperCol={{
              ...layout.wrapperCol,
              lg: {
                offset: 8,
              },
              md: {
                offset: 8,
              },
              sm: {
                offset: 0,
              },
            }}
          >
            <Button type="primary" htmlType="submit" block>
              UPDATE
            </Button>
          </Form.Item>
        </Form>
        <br />
      </Col>
    </Spin>
  );
}

export default Editoperatorprofile;

