import { Form, message, Select, Space, Spin, Table } from "antd";
import "antd/dist/antd.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptDriverInvitation,
  fetchAllDrivers,
  fetchPendingDriverInvitation,
  rejectDriverInvitation,
} from "../store/reducers/driverReducer";

const RequestedDriverListComponent = ({ props }) => {
  const { Option } = Select;
  const { Column, ColumnGroup } = Table;
  const access_token = useSelector((state) => state?.persist?.auth.token);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [pendingDriver, setPendingData] = useState([]);
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const driverData = useSelector((state) => state?.persist?.driver?.pending);
  let activeDriver = driverData;

  useEffect(() => {
    if (driverData !== undefined) {
      let pendingData = [];
      for (let i = 0; i < driverData?.length; i++) {
        pendingData.push(driverData[i].driver);
      }
      setPendingData(pendingData);
    }
  }, [driverData]);

  const acceptInvitation = (id) => {
    let payload = {
      token: access_token,
      id: id,
    };
    setLoadingSpin(true);
    dispatch(acceptDriverInvitation(payload)).then((res) => {
      if (res?.payload?.data?.success) {
        setLoadingSpin(false);

        messageApi.open({
          type: "success",
          content: res.payload.data.message,
          className: "custom-class",
          style: {
            marginTop: "10vh",
          },
        });
        dispatch(fetchPendingDriverInvitation(access_token));
        dispatch(dispatch(fetchAllDrivers(access_token)));
      }
      if (res?.payload?.data?.error) {
        setLoadingSpin(false);
        messageApi.open({
          type: "warning",
          content: res.payload.data.error,
          className: "custom-class",
          style: {
            marginTop: "10vh",
          },
        });
        dispatch(fetchPendingDriverInvitation(access_token));
        dispatch(dispatch(fetchAllDrivers(access_token)));
      }
    });
  };

  const rejectInvitation = (id) => {
    let payload = {
      token: access_token,
      id: id,
    };
    setLoadingSpin(true);
    dispatch(rejectDriverInvitation(payload)).then((res) => {
      if (res?.payload?.data?.success) {
        setLoadingSpin(false);

        messageApi.open({
          type: "success",
          content: res.payload.data.message,
          className: "custom-class",
          style: {
            marginTop: "10vh",
          },
        });
        dispatch(fetchPendingDriverInvitation(access_token));
        dispatch(dispatch(fetchAllDrivers(access_token)));
      }
      if (res?.payload?.data?.error) {
        setLoadingSpin(false);
        messageApi.open({
          type: "warning",
          content: res.payload.data.error,
          className: "custom-class",
          style: {
            marginTop: "10vh",
          },
        });
        dispatch(fetchPendingDriverInvitation(access_token));
        dispatch(dispatch(fetchAllDrivers(access_token)));
      }
    });
  };

  return (
    <div>
      {contextHolder}
      <Spin spinning={loadingSpin} size="large">
        <Table
          dataSource={pendingDriver}
          scroll={{
            y: 500,
          }}
          pagination={false}
        >
          <Column title="Driver" dataIndex="name" key="driver" />
          <Column
            title="Date Of Birth"
            dataIndex="dateOfBirth"
            key="datetime"
          />
          <Column
            title="Phone Number"
            dataIndex="phoneNumber"
            key="Phone Number"
          />
          <Column
            title="Action"
            key="action"
            render={(record) => (
              <Space size="middle">
                <a
                  onClick={(e) => {
                    acceptInvitation(record.id);
                  }}
                >
                  Accept
                </a>
                <a
                  onClick={(e) => {
                    rejectInvitation(record.id);
                  }}
                >
                  Reject
                </a>
              </Space>
            )}
          />
        </Table>
      </Spin>
    </div>
  );
};
export default RequestedDriverListComponent;

