import React from "react";
// import GoogleMapReact from "google-map-react";
import { Wrapper } from "@googlemaps/react-wrapper";
import { isLatLngLiteral } from "@googlemaps/typescript-guards";
import { createCustomEqual } from "fast-equals";

const google = window.google;

// function DashboardMap__DEPRECATED() {
//   const defaultProps = {
//     center: {
//       lat: 37.3605,
//       lng: -122.0675,
//     },
//     zoom: 5,
//   };

//   const Marker = ({ text }) => {
//     return (
//       <div>
//         <EnvironmentFilled style={{ fontSize: 30, color: "red" }} />
//         <b>{text}</b>
//       </div>
//     );
//   };

//   const myMarkers = [
//     {
//       name: "Mountain View High School",
//       lat: 37.3605,
//       lng: -122.0675,
//     },
//     {
//       name: "High School",
//       lat: 40.73,
//       lng: -73.93,
//     },
//   ];

//   return (
//     // Important! Always set the container height explicitly

//     <Card
//       title="Active Drivers Locations"
//       style={{
//         width: 850,
//       }}
//       bodyStyle={{ padding: 0 }}
//     >
//       <div style={{ height: "50vh", width: "100vh" }}>
//         <GoogleMapReact
//           bootstrapURLKeys={{
//             key: "AIzaSyAVrMSlcMWyuRyCbSTZDABAS_XG6FcScYQ",
//             language: "en",
//           }}
//           defaultCenter={defaultProps.center}
//           defaultZoom={defaultProps.zoom}
//         >
//           {myMarkers.map((each) => (
//             <Marker key={each.name} lat={each.lat} lng={each.lng} text={each.name} />
//           ))}
//           <Marker
//             lat={37.3605}
//             lng={-122.0675}
//             text={"Mountain View High School"}
//           />
//         </GoogleMapReact>
//       </div>
//     </Card>
//   );
// }

/*
function SingleDriverLogsMap() {
  let fetchDriverLogs = useSelector(
    (state) => state?.persist?.driver?.singleLogsofDriver?.data,
  );

  console.log("singlelogsformap", fetchDriverLogs);
  const defaultProps = {
    center: {
      lat: 37.3605,
      lng: -122.0675,
    },
    zoom: 5,
  };

  const StartMarker = ({ text }) => {
    return (
      <div>
        <EnvironmentFilled style={{ fontSize: 30, color: "green" }} />
        <b>{text}</b>
      </div>
    );
  };

  const StopMarker = ({ text }) => {
    return (
      <div>
        <EnvironmentFilled style={{ fontSize: 30, color: "red" }} />
        <b>{text}</b>
      </div>
    );
  };

  return (
    <Card
      title="Drive Locations"
      style={{
        width: 590,
      }}
      size="small"
      bodyStyle={{ padding: 0 }}
    >
      <div style={{ height: "70vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyAVrMSlcMWyuRyCbSTZDABAS_XG6FcScYQ",
            language: "en",
          }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
        >
          {fetchDriverLogs.map((each) => (
            <StartMarker
              lat={each?.start_coord?.lat}
              lng={each?.start_coord?.lng}
              text={each?.start_coord?.formatted_address}
            />
          ))}
          {fetchDriverLogs.map((each) => (
            <StopMarker
              lat={each?.stop_coord?.lat}
              lng={each?.stop_coord?.lng}
              text={each?.stop_coord?.formatted_address}
            />
          ))}
        </GoogleMapReact>
      </div>
    </Card>
  );
}
*/
function SingleDriverLogsMap() {
  return <p> Sample</p>;
}

function ElogMapComponent(props) {
  console.log(props, "propsdata");
  const [zoom, setZoom] = React.useState(3); // initial zoom
  const [center, setCenter] = React.useState({
    lat: 12,
    lng: 72,
  });
  React.useEffect(() => {
    if (props !== null && props?.locations?.vehicleStartLocations.length >= 0) {
      if (
        props?.locations?.vehicleStartLocations[0]?.latlng?.lat !== null &&
        props?.locations?.vehicleStartLocations[0]?.latlng?.lng !== null
      ) {
        setCenter({
          lat: props?.locations?.vehicleStartLocations[0]?.latlng?.lat,
          lng: props?.locations?.vehicleStartLocations[0]?.latlng?.lng,
        });
        setZoom(15);
      } else if (
        props?.locations?.vehicleStartLocations[1]?.latlng?.lat !== null &&
        props?.locations?.vehicleStartLocations[1]?.latlng?.lng !== null
      ) {
        setCenter({
          lat: props?.locations?.vehicleStartLocations[1]?.latlng?.lat,
          lng: props?.locations?.vehicleStartLocations[1]?.latlng?.lng,
        });
        setZoom(15);
      }
    }
    if (props?.locations?.vehicleStartLocations.length === 0) {
      setCenter({
        lat: -36,
        lng: 174,
      });
      setZoom(3);
    }
  }, [props]);

  return (
    <Wrapper apiKey={"AIzaSyAVrMSlcMWyuRyCbSTZDABAS_XG6FcScYQ"} render={render}>
      <Map
        center={center}
        //onClick={onClick}
        //onIdle={onIdle}
        zoom={zoom}
        style={{
          flexGrow: "1", height: "100%",

        }}
      >
        {props?.locations?.vehicleStartLocations
          ? props?.locations?.vehicleStartLocations?.map((location, i) => (
            <Marker
              key={i + 2}
              position={location?.latlng}
              icon={location?.icon}
              details={location?.details}
            />
          ))
          : null}

        {props?.locations?.vehicleStopLocations
          ? props?.locations?.vehicleStopLocations?.map((location, i) => (
            <Marker
              key={i + 2}
              position={location.latlng}
              icon={location.icon}
              details={location?.details}
            />
          ))
          : null}

        {/* Use this similar to Marker, but send list of coordinates. This will render path. Please see code for RoutePath on how to pass PathCoordinates.  */}
        {/* https://codesandbox.io/s/github/googlemaps/js-samples/tree/sample-polyline-complex/?from-embed */}
        {/* {props?.locations?.pathCoordinates ? (
          <RoutePath
            key={22}
            pathCoordinates={props?.locations?.pathCoordinates}
          />
        ) : null} */}

        {props?.locations?.pathCoordinates
          ? props?.locations?.pathCoordinates?.map((location, i) => (
            <RoutePath key={22 + i} pathCoordinates={location} />
          ))
          : null}
      </Map>
    </Wrapper>
  );
}

const render = (status) => {
  return <h1>{status}</h1>;
};

const Map = ({ onClick, onIdle, children, style, ...options }) => {
  // [START maps_react_map_component_add_map_hooks]
  const ref = React.useRef(null);
  const [map, setMap] = React.useState();

  React.useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);
  // [END maps_react_map_component_add_map_hooks]
  // [START maps_react_map_component_options_hook]
  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);
  // [END maps_react_map_component_options_hook]
  // [START maps_react_map_component_event_hooks]
  React.useEffect(() => {
    if (map) {
      // we do not have any listens
      // ["click", "idle"].forEach((eventName) =>
      //   google.maps.event.clearListeners(map, eventName)
      // );
      // if (onClick) {
      //   map.addListener("click", onClick);
      // }
      // if (onIdle) {
      //   map.addListener("idle", () => onIdle(map));
      // }
    }
  }, [map, onClick, onIdle]);
  // [END maps_react_map_component_event_hooks]
  // [START maps_react_map_component_return]
  return (
    <>
      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          // @ts-ignore
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
  // [END maps_react_map_component_return]
};

// [START maps_react_map_marker_component]
const Marker = (options) => {
  const [marker, setMarker] = React.useState();
  const [infoWindow, setInfoWindow] = React.useState();

  React.useEffect(() => {
    if (!marker) {
      try {
        let q = new window.google.maps.Marker();

        // Popup details code start
        if (options?.details?.detailsType === "Vehicle") {
          const infowindow = new window.google.maps.InfoWindow();
          q.addListener("click", () => {
            const content = options.details
              ? `Address: ${options.details.address}<br>
                 Driver Name: ${options.details.driverName}`
              : "";
            infowindow.setContent(content);
            infowindow.open(q.get("map"), q);
          });
          setInfoWindow(infowindow); // Save the info window instance
        } else if (options?.details?.detailsType === "Driver") {
          const infowindow = new window.google.maps.InfoWindow();
          q.addListener("click", () => {
            const content = options.details
              ? `Address: ${options.details.address}<br>
                 Plate no: ${options.details.vehiclePlateNumber}<br>
                 Make: ${options.details.vehicleMake}<br>
                 Model: ${options.details.vehicleModel}`
              : "";
            infowindow.setContent(content);
            infowindow.open(q.get("map"), q);
          });
          setInfoWindow(infowindow); // Save the info window instance
        } else if (options?.details?.detailsType === "Actively Driving") {
          const infowindow = new window.google.maps.InfoWindow();
          q.addListener("click", () => {
            const content = options.details
              ? `Address: ${options.details.address}<br>
                 Driver Name: ${options.details.driverName}<br>
                 Licence no: ${options.details.driverLicense}<br>
                 Make: ${options.details.vehiclemake}<br>
                 Model: ${options.details.vehiclemodel}`
              : "";
            infowindow.setContent(content);
            infowindow.open(q.get("map"), q);
          });
          setInfoWindow(infowindow); // Save the info window instance
        }

        setMarker(q);
      } catch (error) {
        console.log("failed to create marker", error);
      }
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
        setMarker(null);
      }
      if (infoWindow) {
        infoWindow.setContent("");
      }
    };
  }, [marker, options, infoWindow]);

  React.useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
};
// [END maps_react_map_marker_component]

// [START maps_react_map_path_component]
const RoutePath = (options) => {
  // Options should contain this path
  // const pathCoordinates = [
  //   { lat: 37.772, lng: -122.214 },
  //   { lat: 21.291, lng: -157.821 },
  //   { lat: -18.142, lng: 178.431 },
  //   { lat: -27.467, lng: 153.027 },
  // ];
  const [routePath, setRoutePath] = React.useState();

  React.useEffect(() => {
    if (!routePath) {
      try {
        let tempPath = new google.maps.Polyline({
          path: options.pathCoordinates,
          geodesic: true,
          strokeColor: "#FF0000",
          strokeOpacity: 1.0,
          strokeWeight: 2,
        });

        setRoutePath(tempPath);
      } catch (error) {
        console.log("failed to create path marker", error);
      }
    }

    // remove path from map on unmount
    return () => {
      if (routePath) {
        routePath.setMap(null);
      }
    };
  }, [routePath]);

  React.useEffect(() => {
    if (routePath) {
      routePath.setOptions(options);
    }
  }, [routePath, options]);

  return null;
};
// [END maps_react_map_path_component]

const deepCompareEqualsForMaps = createCustomEqual((deepEqual) => (a, b) => {
  if (
    isLatLngLiteral(a) ||
    a instanceof google.maps.LatLng ||
    isLatLngLiteral(b) ||
    b instanceof google.maps.LatLng
  ) {
    return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
  }
  // TODO extend to other types
  // use fast-equals for other objects
  return deepEqual(a, b);
});

function useDeepCompareMemoize(value) {
  const ref = React.useRef();

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
}

function useDeepCompareEffectForMaps(callback, dependencies) {
  React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
}

export { ElogMapComponent, SingleDriverLogsMap, Map, Marker, RoutePath };

