import { useDispatch, useSelector } from "react-redux";
import { renewAccessToken } from "../store/reducers/authReducer";
import API from "../store/utils/APIUtils";

export const getAllSubcriptionPlansAPIHelper = async () => {
  const response = await API.get(
    `/api/v1/plans/?usertype=operator`,
    {},
    {
      headers: {
        //   Authorization: `token ${payload.accessToken}`,
        // "Content-Type": "application/json",
      },
    },
  );
  return response;
};

export const createBillingAddressAPIHelper = async (payload) => {
  const response = await API.post(
    `/api/v1/operator/billing-address/`,
    {
      address: payload?.address,
      city: payload?.city,
      country: payload?.country,
      state: payload?.state,
      postalcode: payload?.postalcode,
    },
    {
      headers: {
        Authorization: `token ${payload.token}`,
        "Content-Type": "application/json",
      },
    },
  );
  return response;
};

export const getBillingAddressAPIHelper = async (payload) => {
  const response = await API.post(
    `/api/v1/operator/billing-address/`,
    {},
    {
      headers: {
        Authorization: `token ${payload.token}`,
        "Content-Type": "application/json",
      },
    },
  );
  return response;
};

export const preCheckoutAPIHelper = async (token) => {
  const response = await API.get(
    `/api/v1/operator/pre-checkout/`,
    // {},
    {
      headers: {
        Authorization: `token ${token}`,
        "Content-Type": "application/json",
      },
    },
  );
  return response;
};

export const onPaymentSuccessAPIHelper = async (payload) => {
  const response = await API.post(
    `/api/v1/operator/${payload.planId}/checkout/success/`,
    {
      transactionId: payload?.transactionId,
      status: payload?.status,
    },
    {
      headers: {
        Authorization: `token ${payload.token}`,
        "Content-Type": "application/json",
      },
    },
  );
  return response;
};

export const paymentStatusAPIHelper = async (payload) => {
  const response = await API.post(
    `/v1/api/operator/transaction-status/`,
    {
      invoice_id: payload?.invoice_id,
    },
    {
      headers: {
        Authorization: `token ${payload.token}`,
        "Content-Type": "application/json",
      },
    },
  );
  return response;
};

