import API from "../utils/APIUtils";
// import { driverProfileToApp } from "./profileActions";
export const ERROR = "ERROR";
export const SUCCESS = "SUCCESS";
export const DRIVER_LOGIN = "DRIVER_LOGIN";
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
export const AUTH_TOKEN = "AUTH_TOKEN";
export const IS_USER_EXISTS = "IS_USER_EXISTS";
export const FIREBASE_TOKEN = "FIREBASE_TOKEN"; // OTP_RESPONSE OTP_VERIFICATION_RES
export const OTP_RESPONSE = "OTP_RESPONSE";
export const OTP_VERIFICATION_RES = "OTP_VERIFICATION_RES";
export const DRIVER_ONBOARDING = "DRIVER_ONBOARDING";

// import SyncStorage from "sync-storage"; FIREBASE_TOKEN
// import SyncStorage from "@react-native-async-storage/async-storage";

export const error = (payload) => ({
  type: ERROR,
  value: payload,
});
export const success = (payload) => ({
  type: SUCCESS,
  value: payload,
});
export const isUserExists = (payload) => ({
  type: IS_USER_EXISTS,
  value: payload,
});
export const firebaseToken = (payload) => ({
  type: FIREBASE_TOKEN,
  value: payload,
});
export const authToken = (payload) => ({
  type: AUTH_TOKEN,
  value: payload,
});
export const sentOTPResponse = (payload) => ({
  type: OTP_RESPONSE,
  value: payload,
});
export const OTPVerificationResponse = (payload) => ({
  type: OTP_VERIFICATION_RES,
  value: payload,
});
export const show_Loader = () => ({ type: "SHOW_LOADER" });
export const hide_Loader = () => ({ type: "HIDE_LOADER" });

export const driverLogin = (payload) => ({
  type: DRIVER_LOGIN,
  value: payload,
});
export const driverOnboarding = (payload) => ({
  type: DRIVER_ONBOARDING,
  value: payload,
});
export const driverLoginToApp = (payload, callback) => {
  return (dispatch, getState) => {
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    dispatch(show_Loader());
    API.post("/v1/driver/login/", payload, axiosConfig)
      .then(
        (result) => {
          console.log("driverLoginToApp", result);
          dispatch(driverLogin(result.data));
          console.log(result.data);
          // dispatch(driverProfileToApp(result.data.driver.id));
          if (result.data.is_authenticated) {
            callback(result.data.is_authenticated);
          } else {
            callback(result.data.is_authenticated);
            dispatch(error("Incorrect email and password combination"));
          }
        },
        (err) => {
          console.log("error in api", err);

          callback(null);
          dispatch(driverLogin({}));
        },
      )
      .finally(() => {
        dispatch(hide_Loader());
      });
  };
};

export function saveFirebaseToken(payload) {
  console.log("payload data", payload);
  return (dispatch, getState) => {
    dispatch(firebaseToken(payload));
  };
}

export const checkExistingUser = (payload, callback, firebaseToken) => {
  return (dispatch, getState) => {
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    dispatch(show_Loader());
    API.post("v1/api/check/driver/exists/", { email: payload }, axiosConfig)
      .then(
        (result) => {
          dispatch(isUserExists(result.data.user_exists));
          if (result.data.user_exists) {
            console.log("user exists", result.data.user_exists);
            callback(result.data, firebaseToken);
          } else {
            console.log("user exits", result.data.user_exists, firebaseToken);
            callback(result.data);
          }
        },
        (err) => {
          console.log("error in api", err);
          dispatch(isUserExists({}));
        },
      )
      .finally(() => {
        dispatch(hide_Loader());
      });
  };
};

export const driverOnboardingAction = (payload, callback) => {
  return (dispatch, getState) => {
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    dispatch(show_Loader());
    API.post("v1/driver/details/onboarding/", payload, axiosConfig)
      .then(
        (result) => {
          console.log("successfully created driver", result.data);
          dispatch(driverOnboarding(result.data.driver_id.id));
          callback(result.data);
        },
        (err) => {
          console.log("error in api for driver detail", err);
          dispatch(driverOnboarding());
          callback(err);
        },
      )
      .finally(() => {
        dispatch(hide_Loader());
      });
  };
};
export const vehicleOnboardingAction = (payload) => {
  return (dispatch, getState) => {
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    dispatch(show_Loader());
    API.post(
      `v1/${payload.driverId}/driver/vehicle/onboarding/`,
      payload,
      axiosConfig,
    )
      .then(
        (result) => {
          console.log("successfully done", result);
        },
        (err) => {
          console.log("error in api", err);
        },
      )
      .finally(() => {
        dispatch(hide_Loader());
      });
  };
};
export const sendOTPAction = (payload) => {
  return (dispatch, getState) => {
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    console.log("payload otp", payload);
    dispatch(show_Loader());
    API.get(`v1/${payload}/driver/send/otp/`, axiosConfig)
      .then(
        (result) => {
          console.log("successfully done", result); // sentOTPResponse
          dispatch(sentOTPResponse(result.data.success));
        },
        (err) => {
          console.log("error in api", err);
          dispatch(sentOTPResponse(err));
        },
      )
      .finally(() => {
        dispatch(hide_Loader());
      });
  };
};
export const verifyOTPAction = (payload, callback) => {
  console.log("payload", payload);
  return (dispatch, getState) => {
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    dispatch(show_Loader());
    API.post(`v1/${payload.driverId}/driver/verify/otp/`, payload, axiosConfig)
      .then(
        (result) => {
          console.log("successfully done", result);
          if (result.data.success) {
            dispatch(OTPVerificationResponse(result.data.success));
            dispatch(driverLoginToApp(getState().authReducer.firebase_token));
            dispatch(success("successfully verified!"));
            callback(result.data.success);
          } else {
            dispatch(OTPVerificationResponse(result.data.success));
            dispatch(error("OTP verification failed"));
            callback(result.data.success);
          }
        },
        (err) => {
          console.log("error in api", err);
          dispatch(OTPVerificationResponse(err));
          dispatch(error("OTP verification failed"));
          callback(null);
        },
      )
      .finally(() => {
        dispatch(hide_Loader());
      });
  };
};

