import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllDrivers,
  fetchPendingDriverInvitation,
} from "../store/reducers/driverReducer";
import Requesteddriverlistcomponent from "../components/requesteddriverlistcomponent";

function Requesteddriver() {
  const dispatch = useDispatch();
  let access_token = useSelector((state) => state?.persist?.auth?.token);
  let requested_list = useSelector((state) => state?.persist?.driver?.pending);
  console.log("iiii", requested_list);

  useEffect(() => {
    dispatch(fetchPendingDriverInvitation(access_token));
  }, []);

  return (
    <div>
      <Requesteddriverlistcomponent parentToChild={requested_list} />
    </div>
  );
}

export default Requesteddriver;

