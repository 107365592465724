import { useSelector } from "react-redux";
import { Link, Navigate, Route, Routes } from "react-router-dom";
import App from "../App";
import Driverdetailsandactivity from "../pages/driverdetailsandactivity";
import Editoperatorprofile from "../pages/editoperatorprofile";
import Editvehicleform from "../pages/editvehicleform";
import Forgotpassword from "../pages/forgotpassword";
import Operatorprofile from "../pages/operatorprofile";
import Singledriverloghistory from "../pages/singledriverloghistory";
import Singlevehicledetails from "../pages/singlevehicledetails";
import Singlevehicleloghistory from "../pages/singlevehicleloghistory";
import AuthenticationScreen from "../screens/authenticationScreen";
import Dashboardscreen from "../screens/dashboardscreen";
import DriversScreen from "../screens/driverscreen";
import OrganizationScreen from "../screens/organizationScreen";

import { useEffect, useState } from "react";
import { isDesktop } from "react-device-detect";
import DeleteDriverAccount from "../driver/DeleteDriverAccount";
import DriverEmailVerification from "../driver/DriverEmailVerification";
import OnPaymentSuccess from "../pages/onPaymentSuccess";
import SubscriptionPlans from "../pages/subscriptionPlans";
import TeamUserSignup from "../pages/teamUserSignup";
import DeviceDetectModal from "../screens/DeviceDetectModal";
import SettingScreen from "../screens/settingScreen";
import VehiclesScreen from "../screens/vehiclesscreen";
import VehicleBulkImport from "../screens/VehicleBulkImport";
import AssigningMultipleDriverToVehicle from "../screens/AssigningMultipleDriverToVehicle";

export default function Navigations() {
  const is_authenticated = useSelector(
    (state) => state?.persist?.auth?.is_authenticated,
  );
  const [isDesktopScreen, setIsDesktopScreen] = useState(true);

  const handleModalClose = () => {
    setIsDesktopScreen(true);
    if (!is_authenticated) {
      localStorage.removeItem("isDesktop");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("isDesktop") == null && !isDesktop) {
      setIsDesktopScreen(isDesktop);
      localStorage.setItem("isDesktop", isDesktop);
    }
  }, []);

  return (
    <>
      {!isDesktopScreen && <DeviceDetectModal onClose={handleModalClose} />}
      {!is_authenticated ? (
        <Routes>
          <Route path="/" element={<App />}>
            <Route
              path="/login"
              element={<AuthenticationScreen path="login" />}
            />
            <Route
              path="/signup"
              element={<AuthenticationScreen path="signup" />}
            />
            <Route path="/teamUser/signup/:id" element={<TeamUserSignup />} />
            <Route path="/forgotpassword" element={<Forgotpassword />} />
            <Route path="/" element={<Navigate to="/login" />} />
            <Route
              path="*"
              element={
                <main style={{ padding: "1rem" }}>
                  <p>There's nothing here!</p>
                  <Link to="/login">Login</Link>
                </main>
              }
            />
          </Route>
          <Route
            path="/email/verification/:id"
            element={<DriverEmailVerification />}
          />
          <Route
            path="/account/deletion/:id"
            element={<DeleteDriverAccount />}
          />
        </Routes>
      ) : (
        <Routes>
          <Route path="/" element={<App />}>
            {/* <Route
              path="/organization"
              element={<Organization path="organization" />}
            /> */}
            <Route path="/app/Users" element={<OrganizationScreen />} />

            {/* <Route
              path="/login"
              element={<AuthenticationScreen path="login" />}
            /> */}
            <Route path="/app/dashboard" element={<Dashboardscreen />} />
            <Route
              path="/app/dashboard/subscribe"
              element={<SubscriptionPlans />}
            />
            <Route path="/app/vehicles" element={<VehiclesScreen />} />
            <Route path="/app/bulkupload" element={<VehicleBulkImport />} />
            <Route path="/app/drivers" element={<DriversScreen />} />
            <Route path="/app/drivers/listing" element={<DriversScreen />} />
            <Route path="/app/settings" element={<SettingScreen />} />
            <Route
              path="/app/vehicles/vehicledetails/:id"
              element={<Singlevehicledetails />}
            />
            <Route
              path="/app/dashboard/subscribe/success"
              element={<OnPaymentSuccess />}
            />
            <Route
              path="/app/drivers/driver/details/:id"
              element={<Driverdetailsandactivity />}
            />
            <Route
              path="/app/vehicles/editvehicle"
              element={<Editvehicleform />}
            />
            <Route
              path="/app/vehicles/vehicleloghistory"
              element={<Singlevehicleloghistory />}
            />
            <Route
              path="/app/drivers/driversloghistory/:id"
              element={<Singledriverloghistory />}
            />
            <Route
              path="/app/vehicles/driverdetailsandactivity"
              element={<Driverdetailsandactivity />}
            />
            <Route
              path="/app/vehicles/operatorprofile"
              element={<Operatorprofile />}
            />
            <Route path="/app/vehicles/assign-multiple-drivers/:id"
              element={<AssigningMultipleDriverToVehicle />} />
            <Route
              path="/app/editoperatorprofile"
              element={<Editoperatorprofile />}
            />
            {/* <Route path="/app/updatedriver" element={<Updatedriver />} /> */}

            <Route
              path="*"
              element={
                <main style={{ padding: "1rem" }}>
                  <p>There's nothing here!</p>
                  <Link to="/app/dashboard">Back home!</Link>
                </main>
              }
            />
          </Route>
        </Routes>
      )}
    </>
  );
}

