import { Col, Row } from 'antd';
import React from 'react';
import Vehicledetails from '../components/vehicledetails';
import Vehicleloglist from '../components/vehicleloglist';
import Vehicleloghistory from '../components/vehicleloghistory';

  


function Singlevehicleloghistory() {
  return (
    
<div>
  
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
        }} 
      >
       
        <Col span={8}>
          <Vehicledetails />
        </Col>
        <Col span={16}>
          <Vehicleloghistory />
        </Col>
      </Row>
    </div>
  );
}

export default Singlevehicleloghistory;
