import { Col, Row } from "antd";
import { useMatch } from "react-router-dom";
import Driversloghistory from "../components/driverloghistory";

function Singledriverloghistory() {
  console.log("inside dd");
  const {
    params: { id },
  } = useMatch("/app/drivers/driversloghistory/:id");
  // setId(id);
  let mountNode = document.getElementById("container");
  return (
    <div>
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
        }}
        style={{ margin: 0 }}
      >
        <Col>
          <Driversloghistory Id={id} />
        </Col>
      </Row>
    </div>
  );
}

export default Singledriverloghistory;
