import { Avatar, Card, Button } from "antd";
import { Divider } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllSubcriptionPlans } from "../store/reducers/paymentReducer";
import Vehiclesdrivenbydriver from "./vehiclesdrivenbydriver";

const { Meta } = Card;

function Operatorsubscriptionhistory() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigatetosubsciorions = () => {
    console.log("navigation");
    navigate("/app/dashboard/subscribe");
  };

  const SubscriptionDetails = useSelector(
    (state) => state?.persist?.operator?.subscriptionDetails,
  );
  return (
    <div>
      <Card
        title="Your Subscription Plan"
        size="small"
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          marginBottom: 20,
        }}
        headStyle={{ textAlign: "left" }}
      >
        <div style={{ textAlign: "left" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              //   width: 700,
            }}
          >
            {SubscriptionDetails?.expiredAt ? (
              <p style={{ fontSize: "16px", fontWeight: 600 }}>
                {SubscriptionDetails?.plan}
              </p>
            ) : (
              <p style={{ fontSize: "16px", fontWeight: 600 }}>
                Not Subscribed
              </p>
            )}

            <Button type="primary" onClick={navigatetosubsciorions}>
              CHANGE PLAN
            </Button>
          </div>
          {SubscriptionDetails?.expiredAt ? (
            <p style={{ lineHeight: 0 }}>
              Renewal due on:{" "}
              {moment(SubscriptionDetails?.expiredAt).format(
                "DD-MM-YYYY hh:mm A",
              )}
            </p>
          ) : null}
        </div>
      </Card>

      {/* <Card
        title="Transaction History"
        size="small"
        style={{
          //   width: 600,
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          marginTop: 25,
        }}
        headStyle={{ textAlign: "left" }}
      >
        <div style={{ textAlign: "left" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              //   width: 700,
            }}
          >
            <p style={{ fontSize: "16px", fontWeight: 600 }}>Premium</p>
            <p style={{ fontSize: "16px", fontWeight: 600 }}>$22.0</p>
          </div>
          <p style={{ lineHeight: 0 }}>14 Aug 2022 - 11:00 PM</p>
          <p>#2432123</p>
        </div>
        <div style={{ textAlign: "left" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              //   width: 700,
            }}
          >
            <p style={{ fontSize: "16px", fontWeight: 600 }}>Premium</p>
            <p style={{ fontSize: "16px", fontWeight: 600 }}>$22.0</p>
          </div>
          <p style={{ lineHeight: 0 }}>14 Aug 2022 - 11:00 PM</p>
          <p>#2432123</p>
        </div>
      </Card> */}
    </div>
  );
}

export default Operatorsubscriptionhistory;

