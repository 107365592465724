import React, { useRef, useState } from 'react';
import 'antd/dist/antd.css';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table, Modal, Form, Select, Card, Spin, message } from 'antd';
// import Driverslistcomponent from '../components/driverslistcomponent';
import { useDispatch, useSelector } from "react-redux";
import RemoveOperator from '../components/removeoperatorcomponent'

import Highlighter from 'react-highlight-words';
const RemoveOperatorHistory = ({ parentToChild }) => {
  const { Option } = Select;
  const { Column, ColumnGroup } = Table;
  const driverData = useSelector(state => state?.persist?.driver?.drivers);
  console.log("Driver Data" + JSON.stringify(driverData))




  return (
    <div>
      <RemoveOperator />
    </div>
  )
};
export default RemoveOperatorHistory;