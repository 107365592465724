import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Button, Col, Row, Spin, Typography } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import logo from "../assets/images/white-.png";
import { setDriverAccountDeletionData } from "../store/reducers/driverReducer";

const { Text, Title } = Typography;
const styles = {
  text_input: { textAlign: "left", fontSize: "1.8rem", fontWeight: "bold" },
};
export default function DeleteDriverAccount() {
  const [data, setData] = useState(false);
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const driverData = useParams();
  const dispatch = useDispatch();

  return (
    <Spin spinning={loadingSpinner} size="large">
      <div>
        <header className="App-header">
          <div className="App-navbar">
            <div className="App-navitem">
              <Row
                style={{
                  paddingLeft: 10,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={logo}
                  height={60}
                  width={60}
                  style={{ borderRadius: 8 }}
                />
              </Row>
            </div>
          </div>
        </header>
        {data ? (
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 150,
            }}
          >
            <Title direction="horizontal" level={4}>
              {data.success ? (
                <CheckCircleOutlined
                  style={{ fontSize: "10vh", color: "green" }}
                />
              ) : data.success === false ? (
                <CloseCircleOutlined
                  style={{ fontSize: "10vh", color: "red" }}
                />
              ) : null}
            </Title>
            {data?.success ? (
              <>
                <Text style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                  {" "}
                  {"Thank You!"}{" "}
                </Text>
                <Text direction="horizontal" style={styles.text_input}>
                  {"Your Account has been Deleted Successfully"}
                </Text>
                <Text style={{ fontWeight: "bold" }}>
                  You can close the page.
                </Text>
                <Text style={{ fontWeight: "bold" }}>OR</Text>
                <Text style={{ fontWeight: "bold" }}>
                  For more information visit our website
                </Text>
                <Link
                  style={{ marginTop: 5 }}
                  to="https://elogbook.co.nz/"
                  target="_blank"
                >
                  {" "}
                  Elogbook.co.in
                </Link>
              </>
            ) : (
              <>
                <Text style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                  {" "}
                  {"Opps!"}{" "}
                </Text>
                <Text direction="horizontal" style={styles.text_input}>
                  {"Something Went Wrong"}
                </Text>
                <Text style={{ fontWeight: "bold" }}>
                  You can close the page.
                </Text>
                <Text style={{ fontWeight: "bold", margin: 0 }}>OR</Text>
                {/* <Text style={{ fontWeight: 'bold' }}>For more information visit our website</Text>÷ */}
                <Button
                  type="primary"
                  style={{ marginTop: 10 }}
                  onClick={() => window.location.reload(true)}
                >
                  RELOAD & TRY AGAIN
                </Button>
              </>
            )}
          </Col>
        ) : (
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 150,
            }}
          >
            <Title direction="horizontal" level={4}>
              <ExclamationCircleOutlined
                style={{ fontSize: "10vh", color: "orange" }}
              />
            </Title>
            <Text direction="horizontal" style={styles.text_input}>
              Account Deletion
            </Text>
            <Text style={{ fontSize: "1rem" }}>
              Click on the delete button to confirm the account deletion
            </Text>
            <Button
              type="primary"
              style={{ marginTop: 10 }}
              onClick={() => {
                setLoadingSpinner(true);
                dispatch(setDriverAccountDeletionData(driverData?.id))
                  .then((result) => {
                    console.log("driverScreenresult", result.payload);
                    if (result?.payload?.data != null) {
                      const payloadData = result?.payload?.data;
                      setData(payloadData);
                    }
                    setLoadingSpinner(false);
                  })
                  .catch((err) => {
                    console.log("driverScreenresult", err);
                    setLoadingSpinner(false);
                  });
              }}
            >
              DELETE ACCOUNT
            </Button>
          </Col>
        )}
      </div>
    </Spin>
  );
}

