import { Row } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import Diverslistcompponent from '../components/driverslistcomponent'

function DriversList() {
  let drivers_list = useSelector(state => state?.persist?.driver?.drivers?.data);

  return (
    <Row>
       <Diverslistcompponent parentToChild={drivers_list}/>
     </Row>

  );
}

export default DriversList;
