import { Col, Row } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRejectedDriver } from "../store/reducers/driverReducer";
import RejectedDriverComponent from '../components/rejecteddrivercomponent'

function Rejecteddriver() {
  const dispatch = useDispatch();
  let access_token = useSelector(state => state?.persist?.auth?.token);
  let requested_list = useSelector(state => state?.persist?.driver?.pending);
  console.log("iiii", requested_list)

  useEffect(() => {
    dispatch(fetchRejectedDriver(access_token))
  }, [])

  return (
    <div>
      <RejectedDriverComponent parentToChild={requested_list} />
    </div>
  );
}

export default Rejecteddriver;
