import React, { useRef, useState, useEffect } from "react";
import "antd/dist/antd.css";
import { SearchOutlined } from "@ant-design/icons";
import { Table, Select, Spin, message, Space } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { fetchRemoveOperator } from "../store/reducers/operatorReducer";
import moment from "moment";

function RemoveOperatorHistory(props) {
  const { Option } = Select;
  const { Column, ColumnGroup } = Table;
  const [loadingSpin, setLoadingSpinner] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  ///
  const dispatch = useDispatch();
  let token = useSelector((state) => state?.persist?.auth?.token);
  console.log("auth", token);
  let state = useSelector((state) => state);
  console.log("state", state);
  console.log("Active vehicle", state?.persist?.vehicle?.activeVehicle);

  useEffect(() => {
    setLoadingSpinner(true);
    dispatch(fetchRemoveOperator(token)).then((res) => {
      setLoadingSpinner(false);

      if (res.payload.data.error) {
        messageApi.open({
          type: "warning",
          content: res.payload.data.error,
          className: "custom-class",
          style: {
            marginTop: "10vh",
          },
        });
      }
    });
  }, [dispatch]);

  let fetchAllRemoveOperator = useSelector(
    (state) => state?.persist?.operator?.operatorRemove?.data,
  );
  console.log("get vehicles", fetchAllRemoveOperator);

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstname",
      key: "firstname",
    },
    {
      title: "Last Name",
      dataIndex: "lastname",
      key: "lastname",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "make",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "model",
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "type",
    },
    {
      title: "Removed Date",
      key: "assignedTo",
      render: (record) => (
        <Space size="middle">
          {record.deletedDate
            ? new Date(new Date(record.deletedDate))
                .toLocaleDateString("US")
                .replaceAll("/", "-")
            : null}
        </Space>
      ),
    },
  ];

  return (
    <div>
      {contextHolder}
      <Spin spinning={loadingSpin} size="large">
        <Table
          dataSource={fetchAllRemoveOperator}
          scroll={{
            y: 500,
          }}
          columns={columns}
          pagination={false}
          style={{ paddingTop: 10 }}
          onRow={(record) => ({
            style: { cursor: "pointer" },
            onClick: (event) => {
              console.log("onRow onClick", record);
              props.onSelectVehicleHandler(record);
            },
          })}
        ></Table>
      </Spin>
    </div>
  );
}

export default RemoveOperatorHistory;

