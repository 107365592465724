import React, { useRef, useState, useEffect } from "react";
import "antd/dist/antd.css";
import { Table, Select, Spin, message, Input, Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const RemovedDriverHistroy = ({ parentToChild }) => {
  // const { Option } = Select;
  const { Column, ColumnGroup } = Table;
  // const [searchText, setSearchText] = useState('');
  // const [searchedColumn, setSearchedColumn] = useState('');
  // const searchInput = useRef(null);
  const access_token = useSelector((state) => state?.persist?.auth.token);
  const dispatch = useDispatch();
  // const [driver, setUpdateDriver] = useState()
  const driverData = useSelector((state) => state?.persist?.driver?.drivers);
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [filterInput, setFilterInput] = useState("");
  const { Search } = Input;
  const columns = [
    {
      title: "Driver Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Removed By",
      dataIndex: "operatorName",
      key: "operatorName",
    },
    {
      title: "Date",
      dataIndex: "modifiedAt",
      key: "modifiedAt",
      render: (record) => {
        return (
          <div>
            <p>{moment(record.invoice_id).format("DD-MM-YYYY")}</p>
          </div>
        );
      },
    },
    {
      title: "Reason",
      key: "deletedReason",
      dataIndex: "deletedReason",
    },
  ];
  let removedDrivers = [];

  if (driverData !== undefined) {
    driverData.map((driverItem) => {
      if (driverItem.inactive && driverItem.isDeleted) {
        let convertDate = { ...driverItem };
        let date = new Date(convertDate.modifiedAt);
        convertDate.modifiedAt =
          date.getDay() + "/" + date.getMonth() + "/" + date.getFullYear();
        removedDrivers.push(convertDate);
      }
    });
  }
  const filterData = () => {
    if (filterInput === "") return removedDrivers;

    if (isNaN(filterInput)) {
      return removedDrivers.filter(({ name }) =>
        name.toLowerCase().includes(filterInput.toLowerCase()),
      );
    }
  };

  return (
    <div>
      {contextHolder}
      <Spin spinning={loadingSpin} size="large">
        <Row>
          <Search
            placeholder="search driver by name"
            allowClear
            enterButton="Search"
            size="medium"
            onSearch={setFilterInput}
            style={{
              width: "25%",
              marginBottom: "2%",
            }}
          />
          <Table
            dataSource={filterData()}
            scroll={{ y: 400 }}
            columns={columns}
            pagination={false}
            style={{ paddingBottom: 10 }}
          ></Table>
        </Row>
      </Spin>
    </div>
  );
};
export default RemovedDriverHistroy;

