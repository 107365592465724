import { Form, Select, Table } from "antd";
import "antd/dist/antd.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const RejectedDriverComponent = ({ props }) => {
  const { Option } = Select;
  const { Column, ColumnGroup } = Table;
  const access_token = useSelector((state) => state?.persist?.auth.token);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [pendingDriver, setPendingData] = useState([]);

  const driverData = useSelector((state) => state?.persist?.driver?.rejected);
  console.log("insideeeeee,", driverData);

  let activeDriver = driverData;

  useEffect(() => {
    if (driverData !== undefined && driverData !== null) {
      let pendingData = [];
      for (let i = 0; i < driverData.length; i++) {
        pendingData.push(driverData[i].driver);
      }
      setPendingData(pendingData);
    }
  }, [driverData]);

  return (
    <div>
      <Table
        dataSource={pendingDriver}
        scroll={{
          y: 500,
        }}
        pagination={false}
      >
        <Column title="Driver" dataIndex="name" key="driver" />
        <Column title="Date Of Birth" dataIndex="dateOfBirth" key="datetime" />
        <Column
          title="Phone Number"
          dataIndex="phoneNumber"
          key="Phone Number"
        />
      </Table>
    </div>
  );
};
export default RejectedDriverComponent;

