import { Avatar, Card } from "antd";
import { Divider } from 'antd';
import React from "react";

const { Meta } = Card;

const Vehicleloghistory = () => (
  <Card
    title="Log History"
    style={{
      width: 600,
    }}
    
  >
    <div style={{ textAlign: "left"  }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: 500,
        }}
      >
        <h4>Michal Jordan</h4>
        <p>14 Aug, 2022</p>
      </div>
      {/* <div>
        <p>
          <span style={{color: '#0ACF83' }}>Started</span> Koura Reserve Playground ,Rangiora 7400, New
          Zealand
        </p>
        <p>
          <span style={{color: '#FF0000' }}>Stopped</span> 42/7 Silverstream Boulevard, Kaiapoi, Christchurch 8073, New Zealand
        </p>
        <button style={{borderRadius: '0', background: '#1890FF', border: '0', padding: '5px', paddingLeft: '15px', paddingRight: '15px', color: 'white'}}>View Log History</button>
      </div> */}
    </div>
    
    
  </Card>
);

export default Vehicleloghistory;
